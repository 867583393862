import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Typography as MuiTypography, IconButton, Tooltip, Modal, TextField, Select, MenuItem, Button, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import { ThumbsUp, ThumbsDown, Copy, PencilLine, Search, Paperclip } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { sendFeedback } from '../services/AGTService';
import InlineImageSearch from '../search/InlineImageSearch';
import { getThemeStyles } from '../themes/themeConfig';

const textFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
    filter: blur(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0);
  }
`;

const glowAnimation = keyframes`
  0% { 
    filter: blur(0px) brightness(1);
    box-shadow: 0 0 0px rgba(255, 255, 255, 0);
  }
  50% { 
    filter: blur(3px) brightness(1.2);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.3);
  }
  100% { 
    filter: blur(0px) brightness(1);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
  }
`;

const cursorBlink = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

const StyledReactMarkdown = styled(ReactMarkdown)(({ theme, $isNew = false }) => ({
  '& p, & li, & blockquote, & h1, & h2, & h3, & h4, & h5, & h6': {
    opacity: $isNew ? 0 : 1,
    animation: $isNew ? `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards` : 'none',
    '& .word': {
      display: 'inline-block',
      opacity: $isNew ? 0 : 1,
      animation: $isNew ? `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards` : 'none',
    }
  },
  '& p': {
    margin: '0 0 16px 0',
    lineHeight: '1.75',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  '& ul, & ol': {
    margin: '0 0 16px 1.5em',
    padding: 0,
  },
  '& li': {
    margin: '0 0 8px 0',
    lineHeight: '1.75',
  },
  '& li > p': {
    margin: '0 0 8px 0',
    lineHeight: '1.75',
  },
  '& blockquote': {
    borderLeft: '3px solid #4CAF50',
    paddingLeft: '10px',
    margin: '16px 0',
    fontStyle: 'italic',
    lineHeight: '1.75',
  },
  '& code': {
    padding: '2px 4px',
    borderRadius: '4px',
    fontSize: '0.9em',
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  },
  '& pre': {
    margin: '16px 0',
    padding: '12px',
    overflow: 'auto',
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
  },
  '& pre code': {
    padding: 0,
    backgroundColor: 'transparent',
  },
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    margin: '24px 0 16px 0',
    lineHeight: '1.4',
    '&:first-child': {
      marginTop: 0,
    },
  },
  ...getThemeStyles('StyledReactMarkdown', theme.palette.mode),
}));

const ImageContainer = styled(Box)`
  position: relative;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  
  &.loaded {
    opacity: 1;
  }
`;

const BaseImage = styled('img')`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  display: block;
`;

const GlowEffect = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${glowAnimation} 1.5s ease-in-out;
  animation-delay: 0.2s;
  border-radius: 8px;
  pointer-events: none;
`;

const MessageContainer = styled(Box)(({ theme, isUser }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: isUser ? 'flex-end' : 'flex-start',
  position: 'relative',
  width: '100%',
  maxWidth: isUser ? '100%' : '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '8px',
  paddingBottom: '8px',
  opacity: 1,
  transform: 'none',

  '&:last-child': {
    marginBottom: '12px',
  },

  '@media (max-width: 1200px)': {
    maxWidth: '100%',
    paddingRight: isUser ? '16px' : '0',
    paddingLeft: isUser ? '0' : '0px',
  },

  '@media (max-width: 600px)': {
    paddingRight: isUser ? '0px' : '0',
    paddingLeft: isUser ? '0' : '0px',
    maxWidth: '100%',
  },

  ...getThemeStyles('MessageContainer', theme.palette.mode),
}));

const MessageContent = styled(Box)(({ theme, isUser }) => ({
  flexGrow: 0,
  maxWidth: isUser ? '60%' : '100%',
  width: isUser ? 'fit-content' : 'auto',
  padding: '12px 16px',
  borderRadius: '30px',
  position: 'relative',
  marginLeft: isUser ? 'auto' : '0',
  marginRight: isUser ? '0px' : '0',
  lineHeight: '1.75',
  opacity: 1,
  transform: 'none',

  '@media (max-width: 1200px)': {
    marginRight: isUser ? '0' : '0',
    maxWidth: isUser ? '60%' : 'calc(100% - 44px)',
    width: isUser ? 'fit-content' : '100%',
  },

  '@media (max-width: 600px)': {
    maxWidth: isUser ? '60%' : 'calc(100% - 34px)',
    width: isUser ? 'fit-content' : '100%',
    marginRight: '0',
  },

  ...(isUser
    ? {
        ...getThemeStyles('UserMessageContent', theme.palette.mode),
        alignSelf: 'flex-end',
        '& > *': {
          margin: '0',
          '&:not(:last-child)': {
            marginBottom: '16px',
          },
        },
      }
    : {
        ...getThemeStyles('MessageContent', theme.palette.mode),
        '& > *': {
          margin: '0',
          '&:not(:last-child)': {
            marginBottom: '16px',
          },
        },
    }),
}));

const MessageWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  paddingRight: '0',
  marginLeft: '0',
  paddingTop: '8px',
  '@media (max-width: 1200px)': {
    paddingRight: '16px',
    width: '100%',
  },
  '@media (max-width: 600px)': {
    paddingRight: '8px',
  },
});

const AssistantIconContainer = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  width: '34px',
  height: '34px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
  marginLeft: '0px',
  marginTop: '10px',
  opacity: 1,
  
  '@media (max-width: 1200px)': {
    marginRight: '4px',
  },
  '@media (max-width: 600px)': {
    marginRight: '0px',
  },
  ...getThemeStyles('AssistantIconContainer', theme.palette.mode),
}));

const AssistantIcon = styled('img')({
  width: '18px',
  height: '18px',
  objectFit: 'contain',
});

const FeedbackContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '15px',
  bottom: '-5px',
  display: 'flex',
  gap: '5px',
  opacity: 0,
  animation: `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards`,
  animationDelay: '0.6s',
  
  '@media (max-width: 1200px)': {
    position: 'relative',
    left: 'auto',
    bottom: 'auto',
    marginTop: '8px',
    justifyContent: 'flex-start',
  },
}));

const FeedbackButton = styled(IconButton)(({ theme, $active }) => ({
  padding: '2px',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  ...getThemeStyles('FeedbackButton', theme.palette.mode),
  color: $active ? theme.palette.primary.main : 'inherit',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  width: '300px',
  padding: '15px',
  borderRadius: '20px',
  overflow: 'hidden',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backdropFilter: 'blur(10px)',
  ...getThemeStyles('ModalContent', theme.palette.mode),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: '10px',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    transition: 'all 0.2s ease',
    '&:hover': {
      transform: 'translateY(-1px)',
    },
    '&.Mui-focused': {
      transform: 'translateY(-1px)',
    },
  },
  ...getThemeStyles('StyledTextField', theme.palette.mode),
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  marginTop: '10px',
  width: '100%',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-1px)',
  },
  ...getThemeStyles('StyledSelect', theme.palette.mode),
}));

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginTop: '15px',
  gap: '10px',
});

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: '30px',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-1px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  ...getThemeStyles('StyledButton', theme.palette.mode),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: '6px 12px',
  margin: '10px 0',
  borderRadius: '20px',
  transition: 'all 0.2s ease',
  '& .lucide': {
    marginLeft: '8px', // Añadido margen izquierdo para los iconos
  },
  '&:hover': {
    transform: 'translateY(-1px)',
  },
  ...getThemeStyles('StyledButton', theme.palette.mode),
}));

const Typography = styled(MuiTypography)({
  lineHeight: '1.75',
  '& p': {
    margin: '0 0 16px 0',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    marginTop: '24px',
    marginBottom: '16px',
    fontWeight: 600,
    lineHeight: 1.25,
    '&:first-child': {
      marginTop: 0,
    },
  },
  '& h1': { fontSize: '2em' },
  '& h2': { fontSize: '1.5em' },
  '& h3': { fontSize: '1.25em' },
  '& h4': { fontSize: '1em' },
  '& h5': { fontSize: '0.875em' },
  '& h6': { fontSize: '0.85em' },
  '& a': {
    color: 'inherit',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  '& strong': { fontWeight: 600 },
  '& em': { fontStyle: 'italic' },
  '& code': {
    padding: '0.2em 0.4em',
    margin: 0,
    fontSize: '85%',
    borderRadius: '3px',
  },
  '& pre': {
    padding: '16px',
    overflow: 'auto',
    fontSize: '85%',
    lineHeight: 1.45,
    borderRadius: '3px',
    margin: '16px 0',
  },
  '& blockquote': {
    margin: '16px 0',
    padding: '0 16px',
    borderLeft: '4px solid',
    '& p': {
      margin: '8px 0',
    },
  },
  '& ul, & ol': {
    marginTop: '0',
    marginBottom: '16px',
    paddingLeft: '2em',
    '& li': {
      marginTop: '4px',
    },
  },
  '& table': {
    display: 'block',
    width: '100%',
    overflow: 'auto',
    marginTop: '0',
    marginBottom: '16px',
    borderCollapse: 'collapse',
    '& th, & td': {
      padding: '6px 13px',
      border: '1px solid',
    },
    '& tr': {
      borderTop: '1px solid',
      '&:nth-child(2n)': {
        background: 'rgba(0, 0, 0, 0.05)',
      },
    },
  },
  '& img': {
    maxWidth: '100%',
    boxSizing: 'content-box',
    background: 'transparent',
  },
  '& hr': {
    height: '0.25em',
    padding: 0,
    margin: '24px 0',
    border: 0,
  },
  '& kbd': {
    padding: '0.2em 0.4em',
    margin: 0,
    fontSize: '85%',
    borderRadius: '3px',
    border: '1px solid',
  },
  '& sup, & sub': {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
  },
  '& sup': {
    top: '-0.5em',
  },
  '& sub': {
    bottom: '-0.25em',
  },
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
});

const FileAttachmentContainer = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  padding: '8px',
  borderRadius: '8px',
  opacity: 0,
  animation: `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards`,
  animationDelay: '0.2s',
  ...getThemeStyles('FileAttachmentContainer', theme.palette.mode),
}));

const createCustomRenderers = (isNew, baseDelay = 0) => ({
  text: ({ children }) => {
    if (!isNew) return children;
    
    return children.split(' ').map((word, index) => (
      <span
        key={index}
        className="word"
        style={{
          animationDelay: `${baseDelay + index * 40}ms`,
        }}
      >
        {word}{' '}
      </span>
    ));
  },
  code: ({ node, inline, className, children, ...props }) => {
    return (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
  pre: ({ node, children, ...props }) => {
    return (
      <pre {...props}>
        {children}
      </pre>
    );
  }
});

const FeedbackButtonsSection = ({ feedback, handleFeedback, copied, handleCopy, hasAGTMaker, onOpenAGTMaker }) => (
  <FeedbackContainer>
    <Tooltip title="Me gusta" placement="top">
      <FeedbackButton 
        onClick={() => handleFeedback('like')} 
        $active={feedback === 'like'}
        size="small"
      >
        <ThumbsUp size={18} />
      </FeedbackButton>
    </Tooltip>
    <Tooltip title="No me gusta" placement="top">
      <FeedbackButton 
        onClick={() => handleFeedback('dislike')} 
        $active={feedback === 'dislike'}
        size="small"
      >
        <ThumbsDown size={18} />
      </FeedbackButton>
    </Tooltip>
    <Tooltip title={copied ? "Copiado" : "Copiar mensaje"} placement="top">
      <FeedbackButton 
        onClick={handleCopy}
        size="small"
      >
        <Copy size={14} />
      </FeedbackButton>
    </Tooltip>
    {hasAGTMaker && (
      <Tooltip title="Abrir AGT Maker" placement="top">
        <FeedbackButton 
          onClick={onOpenAGTMaker}
          size="small"
        >
          <PencilLine size={14} />
        </FeedbackButton>
      </Tooltip>
    )}
  </FeedbackContainer>
);

const AnimatedImage = ({ src, alt, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  
  return (
    <ImageContainer className={isLoaded ? 'loaded' : ''}>
      <BaseImage
        src={src}
        alt={alt}
        onLoad={() => setIsLoaded(true)}
        loading="lazy"
        {...props}
      />
      {isLoaded && <GlowEffect />}
    </ImageContainer>
  );
};

const OnDemandImageSearch = ({ initialQuery, onImageSelect, showAutomatically }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(showAutomatically);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSearchClick = useCallback(() => {
    setIsSearchVisible(true);
  }, []);

  const handleImageSelect = useCallback((image) => {
    setSelectedImage(image);
    onImageSelect(image);
    setIsSearchVisible(false);
  }, [onImageSelect]);

  if (selectedImage) {
    return (
      <div>
        <AnimatedImage 
          src={selectedImage.link} 
          alt={selectedImage.title}
        />
        <Typography variant="caption">
          <Link href={selectedImage.image.contextLink} target="_blank" rel="noopener noreferrer">
            Ver en la web
          </Link>
        </Typography>
      </div>
    );
  }

  if (isSearchVisible) {
    return <InlineImageSearch onImageSelect={handleImageSelect} initialQuery={initialQuery} />;
  }

  return (
    <StyledButton
    startIcon={<Search size={24} style={{ marginRight: '4px' }} />}
      onClick={handleSearchClick}
    >
      Abrir AGT Search
    </StyledButton>
  );
};

const MagicalMessage = ({ 
  content, 
  isUser, 
  isLastMessage,
  onFeedback, 
  userId, 
  messageId,
  hasAGTMaker,
  onOpenAGTMaker,
  isLoading,
  onUpdateMessage,
  lastImageRequestId,
  files
}) => {
  const initialProcessedContent = useMemo(() => {
    const processedMessage = processMessageContent(content, isLastMessage);
    return {
      type: processedMessage.type,
      text: processedMessage.text,
      data: processedMessage.data,
      isNew: processedMessage.isNew
    };
  }, [content, isLastMessage]);

  const [processedContent, setProcessedContent] = useState([]);
  const [copied, setCopied] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [openLikeModal, setOpenLikeModal] = useState(false);
  const [openDislikeModal, setOpenDislikeModal] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState('');
  const [dislikeReason, setDislikeReason] = useState('Other');

  const handleCopy = useCallback(() => {
    const processedMessage = processMessageContent(content);
    navigator.clipboard.writeText(processedMessage.text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }, [content]);

  const handleFeedback = useCallback((value) => {
    setFeedback(value);
    if (value === 'like') {
      setOpenLikeModal(true);
    } else if (value === 'dislike') {
      setOpenDislikeModal(true);
    }
  }, []);

  const handleSubmitFeedback = useCallback(async () => {
    try {
      if (!userId || !messageId || !feedback) {
        throw new Error('Missing required fields for feedback');
      }

      await sendFeedback(
        userId,
        messageId,
        feedback,
        feedbackDetails,
        feedback === 'dislike' ? dislikeReason : null
      );

      if (onFeedback) {
        onFeedback({
          type: feedback,
          details: feedbackDetails,
          reason: feedback === 'dislike' ? dislikeReason : null,
        });
      }

      setOpenLikeModal(false);
      setOpenDislikeModal(false);
      setFeedbackDetails('');
      setDislikeReason('Other');
    } catch (error) {
      console.error('Error al enviar feedback:', error);
      alert('Hubo un error al enviar el feedback. Por favor, intenta de nuevo.');
    }
  }, [userId, messageId, feedback, feedbackDetails, dislikeReason, onFeedback]);

  const handleImageSelect = useCallback((selectedImage, index) => {
    const imageData = {
      type: 'selected_image',
      data: {
        src: selectedImage.link,
        alt: selectedImage.title,
        contextLink: selectedImage.image.contextLink
      }
    };

    setProcessedContent(prevContent => {
      const newContent = [...prevContent];
      newContent[index] = (
        <React.Fragment key={index}>
          <AnimatedImage 
            src={selectedImage.link} 
            alt={selectedImage.title}
          />
          <br />
          <Link 
            href={selectedImage.image.contextLink} 
            target="_blank" 
            rel="noopener noreferrer"
            sx={{
              display: 'inline-block',
              marginTop: '8px',
              color: 'primary.main',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              }
            }}
          >
            Ver en la web
          </Link>
        </React.Fragment>
      );
      return newContent;
    });
    onUpdateMessage(messageId, imageData);
  }, [onUpdateMessage, messageId]);

  useEffect(() => {
    if (initialProcessedContent.type === 'selected_image') {
      setProcessedContent([
        <React.Fragment key="image">
          <AnimatedImage 
            src={initialProcessedContent.data.src} 
            alt={initialProcessedContent.data.alt}
          />
          {!isUser && (
            <>
              <br />
              <Link 
                href={initialProcessedContent.data.contextLink} 
                target="_blank" 
                rel="noopener noreferrer"
                sx={{
                  display: 'inline-block',
                  marginTop: '8px',
                  color: 'primary.main',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  }
                }}
              >
                Ver en la web
              </Link>
            </>
          )}
        </React.Fragment>
      ]);
      return;
    }

    if (!isUser) {
      const parts = String(initialProcessedContent.text || '').split(/(<agt>.*?<\/agt>|<AGTSearch:image:.*?>)/gs);
      const processedParts = parts.map((part, index) => {
        if (!part) return null;
        
        if (part.startsWith('<agt>') && part.endsWith('</agt>')) {
          return (
            <StyledButton
              key={index}
              startIcon={<PencilLine size={24} style={{ marginRight: '4px' }} />}
              onClick={onOpenAGTMaker}
            >
              Abrir AGT Maker
            </StyledButton>
          );
        }
        
        if (part.startsWith('<AGTSearch:image:')) {
          const imageQuery = part.match(/<AGTSearch:image:(.*?)>/)?.[1] || '';
          if (isImageContent(content)) {
            const imageData = extractImageData(content);
            return imageData && (
              <React.Fragment key={index}>
                <AnimatedImage 
                  src={imageData.src} 
                  alt={imageData.alt}
                />
                <br />
                <Link 
                  href={imageData.contextLink} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  sx={{
                    display: 'inline-block',
                    marginTop: '8px',
                    color: 'primary.main',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    }
                  }}
                >
                  Ver en la web
                </Link>
              </React.Fragment>
            );
          }
          return (
            <OnDemandImageSearch
              key={index}
              initialQuery={imageQuery}
              onImageSelect={(selectedImage) => handleImageSelect(selectedImage, index)}
              showAutomatically={messageId === lastImageRequestId}
            />
          );
        }

        return (
          <StyledReactMarkdown
            key={index}
            $isNew={initialProcessedContent.isNew}
            components={createCustomRenderers(initialProcessedContent.isNew, index * 40)}
            rehypePlugins={[rehypeRaw]}
          >
            {part}
          </StyledReactMarkdown>
        );
      }).filter(Boolean);

      setProcessedContent(processedParts);
    } else {
      setProcessedContent([
        <Typography 
          key="text" 
          style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
        >
          {initialProcessedContent.text}
        </Typography>
      ]);
    }
  }, [
    initialProcessedContent,
    isUser,
    messageId,
    lastImageRequestId,
    onOpenAGTMaker,
    content,
    handleImageSelect
  ]);

  return (
    <MessageContainer isUser={isUser}>
      {isUser ? (
        <>
          <MessageContent isUser={isUser}>
            {processedContent}
          </MessageContent>
          {files?.length > 0 && (
            <FileAttachmentContainer>
              <Typography variant="body2" style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                <Paperclip size={14} style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                Archivos adjuntos: {files.join(', ')}
              </Typography>
            </FileAttachmentContainer>
          )}
        </>
      ) : (
        <MessageWrapper>
          <AssistantIconContainer>
            <AssistantIcon src="/images/Icon/AGT.svg" alt="Assistant" loading="lazy" />
          </AssistantIconContainer>
          <MessageContent isUser={isUser}>
            {processedContent}
            <FeedbackButtonsSection 
              feedback={feedback}
              handleFeedback={handleFeedback}
              copied={copied}
              handleCopy={handleCopy}
              hasAGTMaker={hasAGTMaker}
              onOpenAGTMaker={onOpenAGTMaker}
            />
          </MessageContent>
        </MessageWrapper>
      )}

      <Modal open={openLikeModal} onClose={() => setOpenLikeModal(false)}>
        <ModalContent>
          <Typography variant="h6" style={{ color: 'white', marginBottom: '10px' }}>
            Feedback
          </Typography>
          <StyledTextField
            multiline
            rows={4}
            variant="outlined"
            placeholder="¿Qué te gustó de esta respuesta? (opcional)"
            value={feedbackDetails}
            onChange={(e) => setFeedbackDetails(e.target.value)}
          />
          <ButtonContainer>
            <Button onClick={() => setOpenLikeModal(false)} style={{ color: 'white' }}>
              Cancelar
            </Button>
            <SubmitButton onClick={handleSubmitFeedback} variant="contained">
              Enviar
            </SubmitButton>
          </ButtonContainer>
        </ModalContent>
      </Modal>

      <Modal open={openDislikeModal} onClose={() => setOpenDislikeModal(false)}>
        <ModalContent>
          <Typography variant="h6" style={{ color: 'white', marginBottom: '10px' }}>
            Feedback
          </Typography>
          <StyledSelect
            value={dislikeReason}
            onChange={(e) => setDislikeReason(e.target.value)}
          >
            <MenuItem value="UI bug">Error en la interfaz</MenuItem>
            <MenuItem value="Harmful content">Contenido inapropiado</MenuItem>
            <MenuItem value="Overactive refusal">Rechazo excesivo</MenuItem>
            <MenuItem value="Did not fully follow my request">No siguió completamente mi solicitud</MenuItem>
            <MenuItem value="Not factually correct">Información incorrecta</MenuItem>
            <MenuItem value="Other">Otro</MenuItem>
          </StyledSelect>
          <StyledTextField
            multiline
            rows={4}
            variant="outlined"
            placeholder="¿Qué no te gustó de esta respuesta? (opcional)"
            value={feedbackDetails}
            onChange={(e) => setFeedbackDetails(e.target.value)}
          />
          <ButtonContainer>
            <Button onClick={() => setOpenDislikeModal(false)} style={{ color: 'white' }}>
              Cancelar
            </Button>
            <SubmitButton onClick={handleSubmitFeedback} variant="contained">
              Enviar
            </SubmitButton>
          </ButtonContainer>
        </ModalContent>
      </Modal>
    </MessageContainer>
  );
};

const processMessageContent = (message, isNew = false) => {
  if (!message) return { text: '', type: 'text', isNew };

  if (typeof message === 'string' && message.startsWith('Image: ')) {
    const imageUrl = message.replace('Image: ', '').trim();
    return {
      type: 'selected_image',
      data: {
        src: imageUrl,
        alt: 'Selected image',
        contextLink: imageUrl
      },
      text: message,
      isNew
    };
  }

  if (typeof message === 'string') {
    try {
      const parsed = JSON.parse(message);
      if (parsed.type === 'selected_image' && parsed.data) {
        return { ...parsed, isNew };
      }
      return { text: parsed.text || parsed, type: 'text', isNew };
    } catch {
      return { text: message, type: 'text', isNew };
    }
  }

  if (message?.type === 'selected_image' && message.data) {
    return { ...message, isNew };
  }

  if (message?.text) {
    return { text: message.text, type: 'text', isNew };
  }

  if (typeof message === 'object') {
    try {
      return { text: JSON.stringify(message), type: 'text', isNew };
    } catch {
      return { text: '', type: 'text', isNew };
    }
  }

  return { text: String(message || ''), type: 'text', isNew };
};

const isImageContent = (content) => {
  if (typeof content === 'string' && content.startsWith('Image: ')) {
    return true;
  }
  return content?.type === 'selected_image' && 
         content?.data?.src && 
         content?.data?.contextLink;
};

const extractImageData = (content) => {
  if (typeof content === 'string' && content.startsWith('Image: ')) {
    const imageUrl = content.replace('Image: ', '').trim();
    return {
      src: imageUrl,
      alt: 'Selected image',
      contextLink: imageUrl
    };
  }
  
  if (!isImageContent(content)) return null;
  
  return {
    src: content.data.src,
    alt: content.data.alt || 'Selected image',
    contextLink: content.data.contextLink
  };
};

const MemoizedMagicalMessage = React.memo(MagicalMessage, (prevProps, nextProps) => {
  const isEqual = (
    prevProps.content === nextProps.content &&
    prevProps.isUser === nextProps.isUser &&
    prevProps.isLastMessage === nextProps.isLastMessage &&
    prevProps.userId === nextProps.userId &&
    prevProps.messageId === nextProps.messageId &&
    prevProps.hasAGTMaker === nextProps.hasAGTMaker &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.lastImageRequestId === nextProps.lastImageRequestId &&
    JSON.stringify(prevProps.files) === JSON.stringify(nextProps.files)
  );
  return isEqual;
});

MemoizedMagicalMessage.displayName = 'MagicalMessage';

export default MemoizedMagicalMessage;