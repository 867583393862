import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, List, ListItem, ListItemText, Typography, IconButton, CircularProgress, Snackbar, Alert, Avatar, useMediaQuery, Menu, MenuItem, TextField, Tooltip, Drawer, InputAdornment, Chip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ArrowUp, X, Check, RefreshCw, ArrowRight, ArrowLeft, Search, Calendar, Filter, XCircle, PanelRightClose, PanelRightOpen, PenSquare } from 'lucide-react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { debounce, throttle } from 'lodash';
import { format, isAfter, isBefore, parseISO, isToday, isYesterday, isThisWeek, isThisMonth, isThisYear, subDays, startOfMonth, endOfMonth, isValid } from 'date-fns';
import { getChats, getChatMessages, sendMessageToAGT, createNewChat, getChatContext, updateChatTitle, deleteChat, updateChatTimestamp } from '../services/AGTService';
import ThinkingIcon from './ThinkingIcon';
import PromptCards from './PromptCards';
import MagicalMessage from './MagicalMessage';
import AGTMaker from './AGTMaker';
import OptimizedInput from './OptimizedInput';
import InlineImageSearch from '../search/InlineImageSearch';
import FileAttachment from './FileAttachment';
import CategorizedChatList from './CategorizedChatList';
import { getThemeStyles } from '../themes/themeConfig';

const SCROLL_THRESHOLD = 150;
const BATCH_SIZE = 20;
const SCROLL_SMOOTH_DURATION = 300;
const INTERSECTION_THRESHOLD = 0.5;

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  borderRadius: '20px',
  overflow: 'hidden',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
  },
  ...getThemeStyles('ChatContainer', theme.palette.mode),
}));

const Sidebar = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isOpen' && prop !== '$isMobile',
})(({ theme, $isOpen, $isMobile }) => {
  const sidebarStyles = getThemeStyles('Sidebar', theme.palette.mode);
  const allChatsTextStyles = getThemeStyles('AllChatsText', theme.palette.mode);

  return {
    width: $isOpen ? '300px' : '0',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '20px',
    backdropFilter: $isMobile ? 'none' : 'blur(10px)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 1200,
    transition: 'transform 0.3s ease',
    overflow: 'hidden',
    transform: $isOpen ? 'translateX(0)' : 'translateX(-100%)',
    ...sidebarStyles,
    '& .MuiTypography-root': {
      ...allChatsTextStyles
    }
  };
});

const SidebarContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
}));

const SidebarHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  ...getThemeStyles('SidebarHeader', theme.palette.mode),
}));

const ChatList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  paddingTop: '16px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  ...getThemeStyles('ChatList', theme.palette.mode),
}));

const ChatArea = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isSidebarOpen' && prop !== '$isMobile' && prop !== '$isAGTMakerOpen',
})(({ theme, $isSidebarOpen, $isMobile, $isAGTMakerOpen }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: $isAGTMakerOpen && !$isMobile ? '50%' : '100%',
  transition: 'all 0.3s ease',
  paddingTop: '56px',
  opacity: 1,
  position: $isAGTMakerOpen && !$isMobile ? 'absolute' : 'relative',
  left: $isAGTMakerOpen && !$isMobile ? 0 : 'auto',
  [theme.breakpoints.up('md')]: {
    paddingLeft: $isSidebarOpen ? '300px' : '0',
    transition: 'padding-left 0.3s ease, width 0.3s ease',
  },
  ...getThemeStyles('ChatArea', theme.palette.mode),
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  width: '100%',
  maxWidth: '800px',
  padding: '20px',
  [theme.breakpoints.up('md')]: {
    padding: '20px 0',
  },
  ...getThemeStyles('MessagesContainer', theme.palette.mode),
}));

const MessageBarContainer = styled(Box)(({ theme }) => ({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'sticky',
  bottom: 0,
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  ...getThemeStyles('MessageBarContainer', theme.palette.mode),
}));

const InputContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderRadius: '30px',
  padding: '2px 16px',
  overflow: 'hidden',
  ...getThemeStyles('InputContainer', theme.palette.mode),
}));

const InputRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  ...getThemeStyles('InputRow', theme.palette.mode),
}));

const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  marginTop: '8px',
  ...getThemeStyles('InfoText', theme.palette.mode),
}));

const MobileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
  backgroundColor: 'transparent',
  ...getThemeStyles('MobileHeader', theme.palette.mode),
}));

const DesktopHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
  ...getThemeStyles('DesktopHeader', theme.palette.mode),
}));

const ErrorMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),
  ...getThemeStyles('ErrorMessage', theme.palette.mode),
}));

const RetryButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: '30px',
  ...getThemeStyles('RetryButton', theme.palette.mode),
}));

const FilterChip = styled('div')(({ theme }) => ({
  margin: '2px 8px 8px 0',
  height: '28px',
  borderRadius: '14px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  userSelect: 'none',
  transition: 'background-color 0.2s ease',
  ...getThemeStyles('FilterChip', theme.palette.mode),
}));

const SearchInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: '8px 12px',
  borderRadius: '30px',
  marginBottom: '16px',
  ...getThemeStyles('SearchInputContainer', theme.palette.mode),
}));

const StyledInput = styled('input')(({ theme }) => ({
  flex: 1,
  border: 'none',
  background: 'none',
  outline: 'none',
  fontSize: '14px',
  ...getThemeStyles('StyledInput', theme.palette.mode),
}));

const ClearAllButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  textTransform: 'none',
  padding: '4px 8px',
  minWidth: 'auto',
  marginLeft: 'auto',
  ...getThemeStyles('ClearAllButton', theme.palette.mode),
}));

const AGTMakerContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  width: '50%',
  height: '100%',
  transition: 'transform 0.3s ease',
  transform: 'translateX(100%)',
  zIndex: 1300,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const ChatViewNest = React.memo(({ user, isAuthorized, themeMode, onSendMessage, isLimited, initialView, messages: initialMessages, isLoading: initialIsLoading, onNavigate, mostRecentChatId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    const savedState = localStorage.getItem('sidebarOpen');
    return savedState !== null ? JSON.parse(savedState) : !isMobile;
  });
  const [chats, setChats] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [messages, setMessages] = useState(initialMessages);
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(initialIsLoading);
  const [error, setError] = useState(null);
  const [chatContext, setChatContext] = useState({});
  const [chatTitle, setChatTitle] = useState('');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [menuChatId, setMenuChatId] = useState(null);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [waitingStartTime, setWaitingStartTime] = useState(null);
  const [isNewChatView, setIsNewChatView] = useState(initialView);
  const [retryAction, setRetryAction] = useState(null);
  const [lastMessageId, setLastMessageId] = useState(null);
  const [lastImageRequestId, setLastImageRequestId] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileContents, setFileContents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isAGTMakerOpen, setIsAGTMakerOpen] = useState(false);
  const [currentAGTContent, setCurrentAGTContent] = useState('');
  const [agtMakerReadyCallback, setAGTMakerReadyCallback] = useState(null);
  const [loadingStates, setLoadingStates] = useState({
    messages: false,
    chatInfo: false
  });

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const chatCache = useRef({});
  const imageSearchCache = useRef({});
  const prevAGTMakerState = useRef(isAGTMakerOpen);
  const userToggledSidebar = useRef(false);
  const isFirstRender = useRef(true);
  const messageUpdateQueue = useRef([]);
  const isProcessingQueue = useRef(false);
  const revealTimeoutRef = useRef(null);

  const revealWordsGradually = useCallback((fullResponse, setterFunction, onComplete) => {
  if (!fullResponse) {
    onComplete();
    return () => {};
  }

  const words = fullResponse.split(' ');
  const chunks = [];
  let currentChunk = [];
  let currentLength = 0;

  words.forEach(word => {
    currentChunk.push(word);
    currentLength += word.length;
    
    if (currentLength >= 50 || word.includes('\n')) {
      chunks.push(currentChunk.join(' '));
      currentChunk = [];
      currentLength = 0;
    }
  });

  if (currentChunk.length > 0) {chunks.push(currentChunk.join(' '));
  }

  let isAnimating = true;
  let currentText = '';
  let currentChunkIndex = 0;

  const animate = () => {
    if (!isAnimating) return;

    if (currentChunkIndex < chunks.length) {
      currentText += (currentText ? ' ' : '') + chunks[currentChunkIndex];

      setterFunction(prevMessages => {
        const newMessages = [...prevMessages];
        const lastIndex = newMessages.length - 1;
        if (lastIndex >= 0) {
          newMessages[lastIndex] = {
            ...newMessages[lastIndex],
            text: currentText,
            animation: {
              fadeIn: true,
              slideUp: true,
              chunk: chunks[currentChunkIndex],
              chunkIndex: currentChunkIndex
            }
          };
        }
        return newMessages;
      });

      currentChunkIndex++;

      const chunkLength = chunks[currentChunkIndex - 1].length;
      const baseDelay = 30;
      const maxDelay = 200;
      const delay = Math.min(chunkLength * baseDelay, maxDelay);

      setTimeout(animate, delay);
    } else {
      setterFunction(prevMessages => {
        const newMessages = [...prevMessages];
        const lastIndex = newMessages.length - 1;
        if (lastIndex >= 0) {
          newMessages[lastIndex] = {
            ...newMessages[lastIndex],
            text: fullResponse,
            animation: {
              fadeIn: false,
              slideUp: false,
              complete: true
            }
          };
        }
        return newMessages;
      });
      onComplete();
    }
  };

  requestAnimationFrame(animate);

  return () => {
    isAnimating = false;
  };
}, []);
  
  const filterCategories = useMemo(() => [
    { name: 'Today', tooltip: 'Show chats from today' },
    { name: 'Yesterday', tooltip: 'Show chats from yesterday' },
    { name: 'Previous 7 Days', tooltip: 'Show chats from the last week' },
    { name: 'Previous 30 Days', tooltip: 'Show chats from the last month' },
    { name: 'Older', tooltip: 'Show older chats' }
  ], []);
  
  const saveCurrentChatId = useCallback((chatId) => {
    if (chatId) {
      localStorage.setItem('currentChatId', chatId);
    } else {
      localStorage.removeItem('currentChatId');
    }
  }, []);
  
  const updateChatsOrder = useCallback(() => {
    setChats(prevChats => {
      const sortedChats = [...prevChats].sort((a, b) => {
        const dateA = new Date(a.updatedAt || a.createdAt);
        const dateB = new Date(b.updatedAt || b.createdAt);
        return dateB - dateA;
      });
      return sortedChats;
    });
  }, []);
  
  const handleOpenAGTMaker = useCallback((content, readyCallback) => {
    setCurrentAGTContent(content);
    setIsAGTMakerOpen(true);
    setAGTMakerReadyCallback(() => readyCallback);
  }, []);
  
  const handleCloseAGTMaker = useCallback(() => {
    setIsAGTMakerOpen(false);
    setCurrentAGTContent('');
    setAGTMakerReadyCallback(null);
  }, []);
  
  const handleAGTMakerContentReady = useCallback(() => {
    if (agtMakerReadyCallback) {
      agtMakerReadyCallback();
      setAGTMakerReadyCallback(null);
    }
  }, [agtMakerReadyCallback]);
  
  const loadChats = useCallback(async () => {
    if (!user || !user.uid) return;
    setIsLoading(true);
    setError(null);
    try {
      const loadedChats = await getChats(user.uid);
      setChats(loadedChats);
      updateChatsOrder();
    } catch (error) {
      console.error('Error loading chats:', error);
      setError('Error loading chats. Please try again.');
      setRetryAction(() => loadChats);
      setChats([]);
    } finally {
      setIsLoading(false);
    }
  }, [user, updateChatsOrder]);
  
  const debouncedLoadChats = useMemo(
    () => debounce(loadChats, 300),
    [loadChats]
  );
  
  const loadChatContext = useCallback(async (chatId) => {
    if (!user || !user.uid) return;
    try {
      const context = await getChatContext(user.uid, chatId);
      setChatContext(context || {});
    } catch (error) {
      console.error('Error loading chat context:', error);
      setError('Error loading chat context. Some conversation history may be missing.');
      setRetryAction(() => () => loadChatContext(chatId));
    }
  }, [user]);
  
  const selectChat = useCallback(async (chatId) => {
    if (!user || !user.uid || chatId === currentChatId) return;
  
    setIsAGTMakerOpen(false);
    setCurrentAGTContent('');
  
    setCurrentChatId(chatId);
    saveCurrentChatId(chatId);
    setLoadingStates({ messages: true, chatInfo: true });
    setError(null);
    setIsNewChatView(false);
  
    try {
      let chatMessages, context;
  
      if (chatCache.current[chatId]) {
        chatMessages = chatCache.current[chatId].messages;
        context = chatCache.current[chatId].context;
      } else {
        [chatMessages, context] = await Promise.all([
          getChatMessages(user.uid, chatId),
          getChatContext(user.uid, chatId)
        ]);
        chatCache.current[chatId] = { messages: chatMessages, context };
      }
  
      setMessages(chatMessages || []);
      setVisibleMessages((chatMessages || []).slice(-20));
      setChatContext(context || {});
  
      const selectedChat = chats.find(chat => chat.id === chatId);
      if (selectedChat) {
        setChatTitle(selectedChat.title || "New chat");
      } else {
        const updatedChats = await getChats(user.uid);
        const updatedSelectedChat = updatedChats.find(chat => chat.id === chatId);
        setChatTitle(updatedSelectedChat ? (updatedSelectedChat.title || "New chat") : "New chat");
        setChats(updatedChats);
        updateChatsOrder();
      }
  
    } catch (error) {
      console.error('Error selecting chat:', error);
      setError('Failed to load chat data. Please try again.');
      setRetryAction(() => () => selectChat(chatId));
      setMessages([]);
      setVisibleMessages([]);
      setChatContext({});
    } finally {
      setLoadingStates({ messages: false, chatInfo: false });
      setTimeout(() => {
        inputRef.current?.focus();
        // Agregar el scroll automático al seleccionar un chat
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 100); // Un pequeño delay para asegurar que los mensajes se han renderizado
    }
  }, [user, chats, currentChatId, saveCurrentChatId, updateChatsOrder]);
  
  const handleUpdateMessage = useCallback((messageId, newContent) => {
    messageUpdateQueue.current.push({ messageId, newContent });
  
    if (!isProcessingQueue.current) {
      isProcessingQueue.current = true;
      requestAnimationFrame(() => {
        const updates = [...messageUpdateQueue.current];
        messageUpdateQueue.current = [];
  
        setMessages(prevMessages => {
          const newMessages = [...prevMessages];
          updates.forEach(update => {
            const index = newMessages.findIndex(msg => msg.id === update.messageId);
            if (index !== -1) {
              newMessages[index] = { ...newMessages[index], text: update.newContent };
            }
          });
          return newMessages;
        });
  
        setVisibleMessages(prevMessages => {
          const newMessages = [...prevMessages];
          updates.forEach(update => {
            const index = newMessages.findIndex(msg => msg.id === update.messageId);
            if (index !== -1) {
              newMessages[index] = { ...newMessages[index], text: update.newContent };
            }
          });
          return newMessages;
        });
  
        isProcessingQueue.current = false;
      });
    }
  }, []);
  
  const handleNewChatInput = useCallback(async (inputMessage) => {
    if (!user || !user.uid || (!inputMessage.trim() && fileContents.length === 0) || isWaitingForResponse) return;
  
    setIsWaitingForResponse(true);
    setWaitingStartTime(Date.now());
  
    try {
      let chatId = currentChatId;
      if (!chatId) {
        const newChat = await createNewChat(user.uid);
        if (!newChat || !newChat.id) {
          throw new Error('Failed to create new chat');
        }
        chatId = newChat.id;
        setCurrentChatId(chatId);
        saveCurrentChatId(chatId);
        setChatTitle("New chat");
        setChats(prevChats => [
          { ...newChat, name: "New chat", updatedAt: new Date().toISOString() },
          ...prevChats
        ]);
        updateChatsOrder();
      }
  
      const messageWithFiles = {
        text: inputMessage,
        files: fileContents
      };
  
      const newMessage = { 
        id: Date.now(), 
        text: JSON.stringify(messageWithFiles), 
        isUser: true, 
        timestamp: Date.now(),
        files: selectedFiles.map(file => file.name)
      };

      setMessages(prevMessages => [...prevMessages, newMessage]);
      setVisibleMessages(prevMessages => [...prevMessages, newMessage]);
      setIsNewChatView(false);

      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  
      const response = await sendMessageToAGT(JSON.stringify(messageWithFiles), user.uid, chatId, {});
      if (!response || !response.reply) {
        throw new Error('Invalid response from AGT');
      }
  
      const responseMessage = { 
        id: Date.now(), 
        text: '', 
        isUser: false, 
        timestamp: Date.now() 
      };
      setLastMessageId(responseMessage.id);
      setMessages(prevMessages => [...prevMessages, responseMessage]);
      setVisibleMessages(prevMessages => [...prevMessages, responseMessage]);
  
      if (response.reply.includes('<AGTSearch:image:')) {
        setLastImageRequestId(responseMessage.id);
      } else {
        setLastImageRequestId(null);
      }
  
      const cleanupReveal = revealWordsGradually(
        response.reply,
        (updater) => {
          setMessages(updater);
          setVisibleMessages(updater);
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        },
        () => {
          setIsWaitingForResponse(false);
          setLastMessageId(null);
        }
      );
  
      setChatContext(response.updatedContext || {});
  
      if (response.title) {
        setChatTitle(response.title);
        setChats(prevChats => 
          prevChats.map(chat => 
            chat.id === chatId ? { ...chat, title: response.title, name: response.title } : chat
          )
        );
        updateChatsOrder();
      }
  
      chatCache.current[chatId] = { 
        messages: [newMessage, {...responseMessage, text: response.reply}], 
        context: response.updatedContext || {}
      };
  
      const newUpdatedAt = new Date().toISOString();
      await updateChatTimestamp(user.uid, chatId);
  
      setChats(prevChats => 
        prevChats.map(chat => 
          chat.id === chatId ? { ...chat, updatedAt: newUpdatedAt } : chat
        )
      );
      updateChatsOrder();
  
      if (response.reply.split('\n').length > 15) {
        handleOpenAGTMaker(response.reply, () => {});
      }
  
      setSelectedFiles([]);
      setFileContents([]);
    } catch (error) {
      console.error('Error in handleNewChatInput:', error);
      setError('Error al crear nuevo chat o enviar mensaje. Por favor, intenta de nuevo.');
      setRetryAction(() => () => handleNewChatInput(inputMessage));
    } finally {
      setIsWaitingForResponse(false);
      inputRef.current?.focus();
    }
  }, [user, currentChatId, sendMessageToAGT, saveCurrentChatId, revealWordsGradually, handleOpenAGTMaker, selectedFiles, fileContents, updateChatTimestamp, updateChatsOrder]);
  
  const handleSendMessage = useCallback(async (messageToSend) => {
    if (!user || !user.uid || (!messageToSend.trim() && fileContents.length === 0) || isWaitingForResponse) return;
  
    setIsWaitingForResponse(true);
    setWaitingStartTime(Date.now());
  
    try {
      if (!currentChatId) {
        await handleNewChatInput(messageToSend);
        return;
      }
  
      const messageWithFiles = {
        text: messageToSend,
        files: fileContents
      };
  
      const newMessage = { 
        id: Date.now(), 
        text: JSON.stringify(messageWithFiles), 
        isUser: true, 
        timestamp: Date.now(),
        files: selectedFiles.map(file => file.name)
      };
      
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setVisibleMessages(prevMessages => [...prevMessages, newMessage]);
      
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  
      const response = await sendMessageToAGT(JSON.stringify(messageWithFiles), user.uid, currentChatId, chatContext);
      if (!response || !response.reply) {
        throw new Error('Invalid response from AGT');
      }
  
      const responseMessage = { 
        id: Date.now(), 
        text: '', 
        isUser: false, 
        timestamp: Date.now() 
      };
      setLastMessageId(responseMessage.id);
      setMessages(prevMessages => [...prevMessages, responseMessage]);
      setVisibleMessages(prevMessages => [...prevMessages, responseMessage]);
  
      if (response.reply.includes('<AGTSearch:image:')) {
        setLastImageRequestId(responseMessage.id);
      } else {
        setLastImageRequestId(null);
      }
  
      const cleanupReveal = revealWordsGradually(
        response.reply, 
        (updater) => {
          setMessages(updater);
          setVisibleMessages(updater);
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        },
        () => {
          setIsWaitingForResponse(false);
          setLastMessageId(null);
        }
      );
  
      setChatContext(response.updatedContext || {});chatCache.current[currentChatId] = {
        messages: [...(chatCache.current[currentChatId]?.messages || []), newMessage, {...responseMessage, text: response.reply}],
        context: response.updatedContext || {}
      };
  
      const newUpdatedAt = new Date().toISOString();
      await updateChatTimestamp(user.uid, currentChatId);
  
      setChats(prevChats => {
        const updatedChats = prevChats.map(chat => 
          chat.id === currentChatId ? { ...chat, updatedAt: newUpdatedAt } : chat
        );
        return updatedChats;
      });
      updateChatsOrder();
  
      if (response.title) {
        setChatTitle(response.title);
        setChats(prevChats => 
          prevChats.map(chat => 
            chat.id === currentChatId ? { ...chat, title: response.title, name: response.title, updatedAt: newUpdatedAt } : chat
          )
        );
        updateChatsOrder();
      }
      
      if (response.reply.split('\n').length > 15) {
        handleOpenAGTMaker(response.reply, () => {});
      }
  
      setSelectedFiles([]);
      setFileContents([]);
    } catch (error) {
      console.error('Error in handleSendMessage:', error);
      setError('Error al enviar mensaje. Por favor, intenta de nuevo.');
      setRetryAction(() => () => handleSendMessage(messageToSend));
    } finally {
      setIsWaitingForResponse(false);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [currentChatId, user, chatContext, isWaitingForResponse, handleNewChatInput, sendMessageToAGT, revealWordsGradually, handleOpenAGTMaker, selectedFiles, fileContents, updateChatTimestamp, updateChatsOrder]);
  
  const handleFileSelect = useCallback((files, contents) => {
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    if (Array.isArray(contents)) {
      setFileContents(prevContents => [...prevContents, ...contents]);
    } else if (contents) {
      setFileContents(prevContents => [...prevContents, contents]);
    }
  }, []);
  
  const handleRemoveFile = useCallback((fileToRemove) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    setFileContents(prevContents => {
      if (Array.isArray(prevContents)) {
        return prevContents.filter(content => content.name !== fileToRemove.name);
      }
      return prevContents;
    });
  }, []);
  
  const handleTitleEdit = useCallback(() => {
    setIsEditingTitle(true);
    setEditedTitle(chatTitle);
  }, [chatTitle]);
  
  const handleTitleSave = useCallback(async () => {
    if (!user || !user.uid || !currentChatId) return;
    try {
      const response = await updateChatTitle(user.uid, currentChatId, editedTitle);
      if (response && response.success) {
        setChatTitle(editedTitle);
        setChats(prevChats => 
          prevChats.map(chat => 
            chat.id === currentChatId 
              ? { ...chat, title: editedTitle, name: editedTitle, updatedAt: response.updatedChat.updatedAt }
              : chat
          )
        );
        updateChatsOrder();
        setIsEditingTitle(false);
        setSnackbarMessage('Chat title updated successfully');
        setSnackbarOpen(true);
      } else {
        throw new Error(response?.error || 'Failed to update chat title');
      }
    } catch (error) {
      console.error('Error in handleTitleSave:', error);
      setError('Failed to update chat title. Please try again.');
      setSnackbarMessage('Failed to update chat title. Please try again.');
      setSnackbarOpen(true);
      setRetryAction(() => handleTitleSave);
    }
  }, [user, currentChatId, editedTitle, updateChatsOrder]);
  
  const toggleSidebar = useCallback(() => {
    userToggledSidebar.current = true;
    const newState = !isSidebarOpen;
    setIsSidebarOpen(newState);
    localStorage.setItem('sidebarOpen', JSON.stringify(newState));
    setTimeout(() => {
      userToggledSidebar.current = false;
    }, 100);
  }, [isSidebarOpen]);
  
  const handleMenuOpen = useCallback((event, chatId) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom,
      left: rect.right,
    });
    setMenuAnchorEl(event.currentTarget);
    setMenuChatId(chatId);
  }, []);
  
  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
    setMenuChatId(null);
  }, []);
  
  const handleDeleteChat = useCallback(async () => {
    if (!user || !user.uid || !menuChatId) return;
    try {
      await deleteChat(user.uid, menuChatId);
      setChats(prevChats => prevChats.filter(chat => chat.id !== menuChatId));
      if (currentChatId === menuChatId) {
        setCurrentChatId(null);
        saveCurrentChatId(null);
        setChatTitle('');
        setMessages([]);
        setVisibleMessages([]);
        setChatContext({});
        setIsNewChatView(true);
        setIsAGTMakerOpen(false);
        setCurrentAGTContent('');
      }
      delete chatCache.current[menuChatId];
      setSnackbarMessage('Chat deleted successfully');
      setSnackbarOpen(true);
      updateChatsOrder();
    } catch (error) {
      console.error('Error in handleDeleteChat:', error);
      setError('Failed to delete chat. Please try again.');
      setSnackbarMessage('Failed to delete chat. Please try again.');
      setSnackbarOpen(true);
      setRetryAction(() => handleDeleteChat);
    } finally {
      handleMenuClose();
    }
  }, [user, menuChatId, currentChatId, handleMenuClose, saveCurrentChatId, updateChatsOrder]);
  
  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);
  
  const toggleFilter = useCallback(() => {
    setIsFilterOpen(prev => !prev);
  }, []);
  
  const handleFilterToggle = useCallback((filter) => {
    setSelectedFilters(prev => 
      prev.includes(filter)
        ? prev.filter(f => f !== filter)
        : [...prev, filter]
    );
  }, []);
  
  const clearAllFilters = useCallback(() => {
    setSelectedFilters([]);
    setSearchTerm('');
    setDateFilter(null);
  }, []);
  
  const getChatCategory = useCallback((chat) => {
    if (!chat.updatedAt && !chat.createdAt) {
      console.warn('Chat sin fecha:', chat);
      return 'Unknown';
    }
  
    const date = parseISO(chat.updatedAt || chat.createdAt);
  
    if (!isValid(date)) {
      console.warn('Fecha inválida para el chat:', chat);
      return 'Unknown';
    }
  
    const now = new Date();
    if (isToday(date)) return 'Today';
    if (isYesterday(date)) return 'Yesterday';
    if (isAfter(date, subDays(now, 7))) return 'Previous 7 Days';
    if (isAfter(date, subDays(now, 30))) return 'Previous 30 Days';
    if (isThisYear(date)) {
      return format(date, 'MMMM');
    }
    return format(date, 'yyyy');
  }, []);
  
  const handleRetry = useCallback(() => {
    if (retryAction) {
      retryAction();
      setRetryAction(null);
      setError(null);
    }
  }, [retryAction]);
  
  const renderErrorMessage = useCallback(() => {
    if (!error) return null;
    return (
      <ErrorMessage>
        <Typography color="text.primary" gutterBottom>{error}</Typography>
        {retryAction && (
          <RetryButton
            variant="contained"
            startIcon={<RefreshCw />}
            onClick={handleRetry}
          >
            RETRY
          </RetryButton>
        )}
      </ErrorMessage>
    );
  }, [error, retryAction, handleRetry]);
  
  const createNewChatHandler = useCallback(() => {
    setCurrentChatId(null);
    saveCurrentChatId(null);
    setMessages([]);
    setVisibleMessages([]);
    setChatContext({});
    setChatTitle('');
    setIsNewChatView(true);
    setIsAGTMakerOpen(false);
    setCurrentAGTContent('');
  }, [saveCurrentChatId]);
  
  const loadMoreMessages = useCallback(() => {
    const currentLength = visibleMessages.length;
    const newMessages = messages.slice(-currentLength - 20, -currentLength);
    setVisibleMessages(prevMessages => [...newMessages, ...prevMessages]);
  }, [messages, visibleMessages]);

  const filteredChats = useMemo(() => {
    let filtered = chats.filter(chat => chat && (chat.updatedAt || chat.createdAt)).sort((a, b) => {
      const dateA = new Date(a.updatedAt || a.createdAt);
      const dateB = new Date(b.updatedAt || b.createdAt);
      return dateB - dateA;
    });
  
    if (searchTerm.trim()) {
      filtered = filtered.filter(chat => 
        chat.title?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  
    if (selectedFilters.length > 0) {
      filtered = filtered.filter(chat => {
        const category = getChatCategory(chat);
        return selectedFilters.includes(category) || 
               (selectedFilters.includes('Older') && !['Today', 'Yesterday', 'Previous 7 Days', 'Previous 30 Days'].includes(category));
      });
    }
  
    if (dateFilter) {
      filtered = filtered.filter(chat => {
        const chatDate = parseISO(chat.updatedAt || chat.createdAt);
        return isValid(chatDate) && isAfter(chatDate, startOfMonth(dateFilter)) && isBefore(chatDate, endOfMonth(dateFilter));
      });
    }
  
    return filtered;
  }, [chats, searchTerm, selectedFilters, dateFilter, getChatCategory]);

  const renderSidebar = useCallback(() => {
    return (
      <>
        <SidebarHeader>
          <IconButton onClick={toggleSidebar}>
            {isSidebarOpen ? <PanelRightClose /> : <PanelRightOpen />}
          </IconButton>
          <IconButton onClick={createNewChatHandler}>
            <PenSquare />
          </IconButton>
        </SidebarHeader>
        <SidebarContent>
          <Box sx={{ padding: '16px' }}>
            <SearchInputContainer>
              <Search size={20} style={{ marginRight: '8px' }} />
              <StyledInput
                placeholder="Search chats"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <Tooltip title={isFilterOpen ? "Hide filters" : "Show filters"}>
                <IconButton size="small" onClick={toggleFilter}>
                  <Calendar color={isFilterOpen || dateFilter ? "primary" : "inherit"} size={20} />
                </IconButton>
              </Tooltip>
            </SearchInputContainer>
            {isFilterOpen && (
              <Box display="flex" flexWrap="wrap" mb={2} mt={2} alignItems="center">
                {filterCategories.map((category) => (
                  <Tooltip key={category.name} title={category.tooltip}>
                    <FilterChip
                      onClick={() => handleFilterToggle(category.name)}
                      className={selectedFilters.includes(category.name) ? 'selected' : ''}
                    >
                      {category.name}
                    </FilterChip>
                  </Tooltip>
                ))}
                {(selectedFilters.length > 0 || searchTerm || dateFilter) && (
                  <ClearAllButton onClick={clearAllFilters} startIcon={<XCircle size={16} />}>
                    Clear all
                  </ClearAllButton>
                )}
              </Box>
            )}
          </Box>
          <CategorizedChatList
            chats={filteredChats}
            currentChatId={currentChatId}
            onSelectChat={selectChat}
            onMenuOpen={handleMenuOpen}
          />
        </SidebarContent>
      </>
    );
  }, [
    toggleSidebar,
    createNewChatHandler,
    searchTerm,
    handleSearchChange,
    isFilterOpen,
    dateFilter,
    toggleFilter,
    filterCategories,
    selectedFilters,
    handleFilterToggle,
    clearAllFilters,
    filteredChats,
    currentChatId,
    selectChat,
    handleMenuOpen,
    isSidebarOpen
  ]);
  
  const renderHeader = useCallback(() => (
    <>
      {isMobile ? (
        <MobileHeader>
          <IconButton onClick={toggleSidebar}>
            {isSidebarOpen ? <PanelRightClose /> : <PanelRightOpen />}
          </IconButton>
          <Typography 
            variant="h6" 
            sx={{ 
              position: 'absolute', 
              left: '50%', 
              transform: 'translateX(-50%)',
              width: '60%',
              textAlign: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {currentChatId && !isNewChatView ? (chatTitle || "AGT Chat") : "New Chat"}
          </Typography>
          <IconButton onClick={createNewChatHandler}>
            <PenSquare />
          </IconButton>
        </MobileHeader>
      ) : (
        <DesktopHeader>
          <Box width="33.33%" display="flex" justifyContent="flex-start">
            {!isSidebarOpen && (
              <>
                <IconButton onClick={toggleSidebar}>
                  <PanelRightOpen />
                </IconButton>
                <IconButton onClick={createNewChatHandler}>
                  <PenSquare />
                </IconButton>
              </>
            )}
          </Box>
          <Box width="33.33%" display="flex" justifyContent="center">
            {currentChatId && !isNewChatView ? (
              isEditingTitle ? (
                <TextField
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onBlur={handleTitleSave}
                  onKeyPress={(e) => e.key === 'Enter' && handleTitleSave()}
                  autoFocus
                  variant="standard"
                  sx={{ 
                    input: { textAlign: 'center' },
                    '& .MuiInput-underline:before': { borderBottomColor: 'text.primary' },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: 'text.primary' },
                  }}
                />
              ) : (
                <Typography 
                  variant="h6" 
                  sx={{ 
                    cursor: 'pointer',
                  }}
                  onClick={handleTitleEdit}
                >
                  {chatTitle}
                </Typography>
              )
            ) : (
              <Typography variant="h6">
                New Chat
              </Typography>
            )}
          </Box>
          <Box width="33.33%" display="flex" justifyContent="flex-end">
          </Box>
        </DesktopHeader>
      )}
    </>
  ), [isMobile, toggleSidebar, isSidebarOpen, currentChatId, isNewChatView, chatTitle, createNewChatHandler, isEditingTitle, editedTitle, handleTitleSave, handleTitleEdit]);
  
  useEffect(() => {
    if (mostRecentChatId && isFirstRender.current) {
      selectChat(mostRecentChatId);
      isFirstRender.current = false;
    }
  }, [mostRecentChatId, selectChat]);
  
  useEffect(() => {
    if (!isMobile && !userToggledSidebar.current) {
      if (isAGTMakerOpen !== prevAGTMakerState.current) {
        const savedSidebarState = localStorage.getItem('sidebarOpen');
        if (isAGTMakerOpen) {
          localStorage.setItem('sidebarStateBeforeAGTMaker', JSON.stringify(isSidebarOpen));
          setIsSidebarOpen(false);
        } else {
          const stateBeforeAGTMaker = localStorage.getItem('sidebarStateBeforeAGTMaker');
          if (stateBeforeAGTMaker !== null) {
            setIsSidebarOpen(JSON.parse(stateBeforeAGTMaker));
          } else if (savedSidebarState !== null) {
            setIsSidebarOpen(JSON.parse(savedSidebarState));
          }
        }
        prevAGTMakerState.current = isAGTMakerOpen;
      }
    }
  }, [isAGTMakerOpen, isMobile, isSidebarOpen]);
  
  useEffect(() => {
    if (!isAGTMakerOpen) {
      localStorage.setItem('sidebarOpen', JSON.stringify(isSidebarOpen));
    }
  }, [isSidebarOpen, isAGTMakerOpen]);

  useEffect(() => {
    if (user && user.uid) {
      if (isInitialLoad) {
        loadChats();
        setIsInitialLoad(false);
      } else {
        debouncedLoadChats();
      }
    }
  }, [user, isInitialLoad, debouncedLoadChats, loadChats]);

  useEffect(() => {
    if (!user || !user.uid) return;

    const updateChats = async () => {
      try {
        const updatedChats = await getChats(user.uid);
        setChats(updatedChats);
        updateChatsOrder();
        if (currentChatId) {
          const currentChat = updatedChats.find(chat => chat.id === currentChatId);
          if (currentChat) {
            setChatTitle(currentChat.title || "New chat");
          }
        }
      } catch (error) {
        console.error('Error updating chats:', error);
        setError('Error updating chats. Please try again.');
      }
    };

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        updateChats();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [user, currentChatId, updateChatsOrder]);

  useEffect(() => {
    const savedChatId = localStorage.getItem('currentChatId');
    if (savedChatId && user && user.uid) {
      selectChat(savedChatId);
    }
  }, [user, selectChat]);

  useEffect(() => {
    if (!isWaitingForResponse) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [isWaitingForResponse]);

  return (
    <ChatContainer>
      <Sidebar $isOpen={isSidebarOpen} $isMobile={isMobile}>
        {renderSidebar()}
      </Sidebar>

      <ChatArea 
        $isSidebarOpen={isSidebarOpen && !isMobile}
        $isMobile={isMobile}
        $isAGTMakerOpen={isAGTMakerOpen && !isMobile}
        className={loadingStates.messages || loadingStates.chatInfo ? 'loading' : ''}
      >
        {renderHeader()}

        <Box 
          display="flex" 
          flexDirection="column" 
          height="100%"
        >
          <Box flexGrow={1} overflow="auto">
            {isNewChatView || (!currentChatId && messages.length === 0) ? (
              <Box 
                display="flex" 
                flexDirection="column" 
                justifyContent="center" 
                alignItems="center" 
                height="100%"
                padding="20px"
              >
                <PromptCards onSelectPrompt={handleNewChatInput} />
              </Box>
            ) : (
              <MessagesContainer>
                {visibleMessages.map((message, index) => {
                  const isLastAssistantMessage = !message.isUser && message.id === lastMessageId;

                  const hasAGTMaker = (() => {
                    if (!message.text) return false;
                    try {
                      const parsed = JSON.parse(message.text);
                      if (parsed.text) {
                        return typeof parsed.text === 'string' && parsed.text.includes('<agt>');
                      }
                      return typeof parsed === 'string' && parsed.includes('<agt>');
                    } catch {
                      return typeof message.text === 'string' && message.text.includes('<agt>');
                    }
                  })();

                  return (
                    <MagicalMessage
                      key={message.id || index}
                      content={message.text}
                      isUser={message.isUser}
                      isLastMessage={isLastAssistantMessage}
                      onFeedback={(feedback) => {}}
                      userId={user?.uid}
                      messageId={message.id}
                      hasAGTMaker={hasAGTMaker}
                      onOpenAGTMaker={() => hasAGTMaker && handleOpenAGTMaker(message.text, () => {})}
                      isLoading={message.isLoading}
                      onUpdateMessage={handleUpdateMessage}
                      lastImageRequestId={lastImageRequestId}
                      files={message.files}
                    />
                  );
                })}
                {isWaitingForResponse && (
                  <ThinkingIcon key="thinking-icon" startTime={waitingStartTime} />
                )}
                {renderErrorMessage()}
                <div ref={messagesEndRef} />
              </MessagesContainer>
            )}
          </Box>

          <MessageBarContainer>
            <InputContainer>
              <OptimizedInput
                onSendMessage={handleSendMessage}
                isWaitingForResponse={isWaitingForResponse}
                inputRef={inputRef}
                selectedFiles={selectedFiles}
                onRemoveFile={handleRemoveFile}
                renderFileAttachment={() => (
                  isAuthorized && (
                    <FileAttachment
                      onFileSelect={handleFileSelect}
                      disabled={isWaitingForResponse}
                    />
                  )
                )}
              />
            </InputContainer>
            <InfoText>
              By using AGT, you agree to our privacy policies.
            </InfoText>
          </MessageBarContainer>
        </Box>
      </ChatArea>

      <AGTMakerContainer
        sx={{
          transform: isAGTMakerOpen ? 'translateX(0)' : 'translateX(100%)',
        }}
      >
        <AGTMaker 
          content={currentAGTContent}
          isOpen={isAGTMakerOpen}
          onClose={handleCloseAGTMaker}
          onContentReady={handleAGTMakerContentReady}
        />
      </AGTMakerContainer>

      <Menu
        anchorEl={menuAnchorEl}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem 
          onClick={handleDeleteChat}
          sx={{ 
            color: 'error.main',
            '&:hover': { backgroundColor: 'error.light' }
          }}
        >
          <DeleteIcon sx={{ marginRight: 1 }} />
          Delete
        </MenuItem>
      </Menu>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity={error ? "error" : "success"} 
          sx={{ width: '100%' }}
          action={
            error && retryAction ? (
              <Button 
                color="inherit" 
                size="small" 
                onClick={handleRetry}
                sx={{
                  borderRadius: '30px',
                  backgroundColor: 'action.selected',
                  color: 'text.primary',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
              >
                RETRY
              </Button>
            ) : null
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ChatContainer>
  );
});

export default ChatViewNest;