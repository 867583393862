import React from 'react';
import { Box, Typography, Button, Grid, Paper, Tooltip as MuiTooltip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Check, Info } from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#ffffff',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
  width: '100%',
  height: '100%',
  minHeight: '400px',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#e0e0e0'}`,
}));

const ProductTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(1),
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
  fontSize: '1.25rem',
}));

const ComingSoonBadge = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '6px 12px',
  borderRadius: '16px',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
  marginBottom: theme.spacing(2),
}));

const ComingSoonText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 500,
  color: theme.palette.mode === 'dark' ? '#a3a3a3' : '#666666',
  letterSpacing: '0.5px',
}));

const FeatureList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  marginTop: theme.spacing(2.5),
  color: theme.palette.mode === 'dark' ? '#a3a3a3' : '#666666',
  fontSize: '0.875rem',
}));

const FeatureItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(1),
  '& svg:first-of-type': {
    marginRight: theme.spacing(1),
    color: '#10a37f',
    width: 16,
    height: 16,
    flexShrink: 0,
    marginTop: '4px',
  },
}));

const FeatureText = styled('span')({
  display: 'inline',
  alignItems: 'center',
});

const InfoIconWrapper = styled('span')({
  display: 'inline-flex',
  alignItems: 'center',
  marginLeft: '4px',
  verticalAlign: 'text-top',
  position: 'relative',
  top: '-4px',
});

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '30px',
  padding: '10px',
  fontWeight: 600,
  textTransform: 'none',
  fontSize: '0.875rem',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  color: theme.palette.mode === 'dark' ? '#666666' : '#999999',
  '&.Mui-disabled': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
    color: theme.palette.mode === 'dark' ? '#666666' : '#999999',
  },
  marginTop: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    color: '#fff',
    fontSize: 12,
    padding: '8px 12px',
    borderRadius: '4px',
    maxWidth: 220,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  },
  '& .MuiTooltip-arrow': {
    color: 'rgba(0, 0, 0, 1)',
  },
}));

const InfoIcon = styled(Info)(({ theme }) => ({
  width: 14,
  height: 14,
  color: theme.palette.mode === 'dark' ? '#a3a3a3' : '#666666',
  cursor: 'help',
  '&:hover': {
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
  },
}));

const AGTPromo = () => {
  const theme = useTheme();

  const renderProductCard = (title, description, features) => (
    <StyledPaper elevation={0}>
      <Box>
        <ProductTitle variant="h5">{title}</ProductTitle>
        <ComingSoonBadge>
          <ComingSoonText>Coming Soon</ComingSoonText>
        </ComingSoonBadge>
        <Typography 
          variant="body2" 
          sx={{ 
            color: theme.palette.mode === 'dark' ? '#a3a3a3' : '#666666', 
            mb: 2.5, 
            fontSize: '0.875rem', 
            mt: 1 
          }}
        >
          {description}
        </Typography>
        <StyledButton 
          variant="contained" 
          fullWidth
          disabled
        >
          Coming Soon
        </StyledButton>
      </Box>
      <FeatureList>
        {features}
      </FeatureList>
    </StyledPaper>
  );

  return (
    <Box sx={{ width: '100%', height: '100%', overflowY: 'auto', padding: { xs: 2, sm: 3 }, backgroundColor: 'transparent' }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          {renderProductCard(
            "AGT Pro",
            "Achieve more, faster, smarter with AI Pro – make every task effortless.",
            <>
              <FeatureItem><Check /> Upload Larger Files</FeatureItem>
              <FeatureItem><Check /> Solve complex tasks with precision</FeatureItem>
              <FeatureItem>
                <Check />
                <FeatureText>
                  Multimodal Capabilities – Integrate documents, images, and more
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="Seamlessly combine text, images, audio, and more in a single query. Our AI understands and processes multiple types of input to provide comprehensive solutions."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem><Check /> Early Access to New Features</FeatureItem>
              <FeatureItem>
                <Check />
                <FeatureText>
                  Priority Access & Increased Limits – Achieve more, without interruptions
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="Enjoy faster response times, higher usage caps, and priority support. Increased limits on API calls, document processing, and computational resources to handle your most demanding tasks."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem><Check /> Smarter Responses – Get precise, contextual answers instantly</FeatureItem>
              <FeatureItem>
                <Check />
                <FeatureText>
                  AGT Search Pro, Maker Pro, and Video
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="AGT Search Pro: Real-time, multi-image search with interactive maps and live updates. Maker Pro: Create dynamic presentations with animated diagrams, extensive reports, and seamless integration. Video: Instant, context-specific video demonstrations for hands-on learning."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          {renderProductCard(
            "AGT Edu",
            "Transform education now with collaborative, AI-powered learning.",
            <>
              <FeatureItem><Check /> Access to AGT Pro (Search Pro, Maker Pro, and Video)</FeatureItem>
              <FeatureItem>
                <Check />
                <FeatureText>
                  DirectiveAI Mode – Real-time group chats with seamless collaboration
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="Up to 4 people collaborate in a single chat, searching, creating presentations, and generating content with all the tools for university life."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <Check />
                <FeatureText>
                  AGT Academic – Advanced research tools, citation generation, and project planning
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="Access cutting-edge research databases, generate accurate citations in multiple formats, and use AI-powered project planning tools to streamline your academic workflow."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <Check />
                <FeatureText>
                  AGT Growth – Tailored learning strategies for every educational level
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="AI-powered personalized learning paths adapt to your educational level and goals. Get customized study plans, interactive exercises, and progress tracking to accelerate your learning journey."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          {renderProductCard(
            "AGT Ups",
            "Drive your startup's growth and success at every stage with AI tools.",
            <>
              <FeatureItem><Check /> Access to AGT Pro (Search Pro, Maker Pro, and Video)</FeatureItem>
              <FeatureItem>
                <Check />
                <FeatureText>
                  AGT Product – Optimize pricing, sales strategies, and market fit
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="AI-driven product analysis: Categorizes your products, extracts valuable market data, and provides insights on pricing, customer behavior, and optimization strategies. Get tailored recommendations to improve your product's market position and performance."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <Check />
                <FeatureText>
                  AGT Cash Flow – Upload receipts and automate financial updates instantly
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="Effortless financial management: Upload photos of receipts, invoices, or quotes for automatic cash flow updates. AI detects income/expense nature and adjusts your cash flow instantly. Manual entries via chat are also supported. Real-time tracking and reporting for complete financial visibility."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <Check />
                <FeatureText>
                  AGT Supplier Finder – Find and negotiate with suppliers through AI-powered tools
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="AI-assisted supplier discovery and negotiation. Find optimal suppliers based on your criteria, get AI-generated negotiation strategies, and automate communication for better deals and partnerships."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <Check />
                <FeatureText>
                  AGT Council – Get expert advice for complex business decisions
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="Access AI-simulated expert panels providing strategic insights, risk analysis, and tailored recommendations for critical business decisions, available 24/7."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <Check />
                <FeatureText>
                  AGT Growth – Strategies tailored to every stage of your startup's journey
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="AI-powered growth strategies customized for your startup's stage. Get actionable insights on market expansion, customer acquisition, and scaling operations. Includes performance tracking and adaptive recommendations."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AGTPromo;