import axios from 'axios';

const AGT_API_URL = 'https://aojw2f4tm8.execute-api.us-east-2.amazonaws.com/prod';
const STANDARD_TIMEOUT = 300000; // 5 minutos en milisegundos

const api = axios.create({
  baseURL: AGT_API_URL,
  timeout: STANDARD_TIMEOUT,
  withCredentials: true
});

// Interceptor para manejar errores globalmente
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // Log crítico mantenido para debugging de errores de API
      console.error('API Error:', error.response.status, error.response.data);
    }
    return Promise.reject(error);
  }
);

// Definimos los tipos de fondo válidos
const VALID_BACKGROUND_TYPES = ['system', 'light', 'dark'];

export const getChats = async (userId) => {
  try {
    const response = await api.get(`/api/chats/${userId}`);
    return Array.isArray(response.data.chats) 
      ? response.data.chats
          .filter(chat => chat && chat.id)
          .map(chat => ({
            ...chat,
            title: chat.title || chat.name || "New chat",
            updatedAt: chat.updatedAt || chat.createdAt
          }))
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      : [];
  } catch (error) {
    return [];
  }
};

export const getChatMessages = async (userId, chatId) => {
  try {
    if (!chatId) {
      return [];
    }
    const response = await api.get(`/api/chat-messages/${userId}/${chatId}`);
    return response.data.messages || [];
  } catch (error) {
    return [];
  }
};

export const sendMessageToAGT = async (message, userId, chatId, context) => {
  try {
    const endpoint = chatId ? '/api/chat' : '/api/create-chat';
    const payload = chatId 
      ? { message, userId, chatId, context }
      : { userId, message, context };

    const response = await api.post(endpoint, payload);
    
    return {
      reply: response.data.reply,
      updatedContext: response.data.updatedContext,
      title: response.data.title,
      chatId: response.data.chatId || (response.data.chat && response.data.chat.id)
    };
  } catch (error) {
    if (error.code === 'ECONNABORTED') {
      throw new Error('La solicitud ha excedido el tiempo de espera. Por favor, inténtalo de nuevo.');
    }
    throw new Error('Failed to send message. Please try again.');
  }
};

export const createNewChat = async (userId) => {
  try {
    const response = await api.post('/api/create-chat', { userId });
    return {
      id: response.data.chat.id,
      title: response.data.chat.title || "New chat",
      name: response.data.chat.name || "New chat",
      createdAt: response.data.chat.createdAt,
      updatedAt: response.data.chat.updatedAt || response.data.chat.createdAt
    };
  } catch (error) {
    throw new Error('Failed to create new chat. Please try again.');
  }
};

export const getChatContext = async (userId, chatId) => {
  try {
    if (!chatId) {
      return null;
    }
    const response = await api.get(`/api/chat-context/${userId}/${chatId}`);
    return response.data.context;
  } catch (error) {
    return null;
  }
};

export const getAnonymousChatHistory = async (sessionId) => {
  try {
    const response = await api.get(`/api/anonymous-chat/${sessionId}`);
    return response.data.messages || [];
  } catch (error) {
    return [];
  }
};

export const getChatHistory = async (userId) => {
  try {
    const response = await api.get(`/api/chat-history/${userId}`);
    return response.data.messages || [];
  } catch (error) {
    return [];
  }
};

export const updateChatTitle = async (userId, chatId, newTitle) => {
  try {
    const response = await api.put('/api/update-chat-title', {
      userId,
      chatId,
      newTitle
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to update chat title');
  }
};

export const generateChatSummary = async (userId, chatId, generateSummary = false, chatTitle) => {
  try {
    const response = await api.post('/api/generate-summary', {
      userId,
      chatId,
      generateSummary,
      chatTitle,
      isFinal: false
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to generate chat summary');
  }
};

export const deleteChat = async (userId, chatId) => {
  try {
    const response = await api.delete(`/api/delete-chat/${userId}/${chatId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to delete chat');
  }
};

export const sendFeedback = async (userId, messageId, feedbackType, details, reason) => {
  if (!userId || !messageId || !feedbackType) {
    throw new Error('userId, messageId, and feedbackType are required');
  }

  try {
    const response = await api.post('/api/feedback', {
      userId,
      messageId,
      feedbackType,
      details,
      reason
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to send feedback');
  }
};

export const setUserBackgroundPreference = async (userId, backgroundType) => {
  if (!userId) {
    throw new Error('Invalid userId');
  }

  if (!VALID_BACKGROUND_TYPES.includes(backgroundType)) {
    backgroundType = 'system';
  }

  try {
    const payload = { 
      userId, 
      backgroundType
    };

    const response = await api.put('/api/user-background', payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to set user background preference');
  }
};

export const getUserBackgroundPreference = async (userId) => {
  if (!userId) {
    throw new Error('Invalid userId');
  }

  try {
    const response = await api.get(`/api/user-background/${userId}`);
    const backgroundType = response.data.backgroundType;
    
    if (!backgroundType || !VALID_BACKGROUND_TYPES.includes(backgroundType)) {
      await setUserBackgroundPreference(userId, 'system');
      return 'system';
    }
    
    return backgroundType;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      await setUserBackgroundPreference(userId, 'system');
      return 'system';
    }
    return 'system';
  }
};

export const updateChatTimestamp = async (userId, chatId) => {
  try {
    const response = await api.put(`/api/update-chat-timestamp/${userId}/${chatId}`);
    return response.data.updatedAt;
  } catch (error) {
    throw new Error('Failed to update chat timestamp');
  }
};

export default {
  getChats,
  getChatMessages,
  sendMessageToAGT,
  createNewChat,
  getChatContext,
  getAnonymousChatHistory,
  getChatHistory,
  updateChatTitle,
  generateChatSummary,
  deleteChat,
  sendFeedback,
  setUserBackgroundPreference,
  getUserBackgroundPreference,
  updateChatTimestamp
};