import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Box, IconButton, Avatar, Fade, useMediaQuery, Snackbar, Alert, Button, Typography } from '@mui/material';
import { ThemeProvider, styled, useTheme, createTheme, keyframes } from '@mui/material/styles';
import { Paintbrush, Plus } from 'lucide-react';
import ProfileDashboard from './ProfileDashboard';
import HomeInput from '../home/HomeInput';
import ChatViewNest from '../chat/ChatViewNest';
import BackgroundSelector from '../navigation/BackgroundSelector';
import { getAuth } from 'firebase/auth';
import { sendMessageToAGT, getChatHistory, getUserBackgroundPreference, setUserBackgroundPreference, createNewChat } from '../services/AGTService';
import { isAuthorized } from '../auth/AuthorizedUsers';
import { getThemeStyles } from '../themes/themeConfig';
import AGTUps from '../AGTUps/AGTUps';
import AGTEdu from '../AGTEdu/AGTEdu';
import AGTPromo from '../AGTPromo/AGTPromo';

// Constants
const INACTIVITY_TIMEOUT = 2 * 60 * 60 * 1000; // 2 horas en milisegundos
const MESSAGE_CACHE_SIZE = 100;
const NAVIGATION_DEBOUNCE_TIME = 300;

// Styled Components
const FixedContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  transition: 'background-image 0.5s ease-in-out, background-color 0.5s ease-in-out',
  ...getThemeStyles('FixedContainer', theme.palette.mode),
}));

const StyledWindowBar = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  backdropFilter: 'blur(40px)',
  color: theme.palette.text.primary,
  fontFamily: "'Inter', sans-serif",
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  ...getThemeStyles('StyledWindowBar', theme.palette.mode),
}));

const TopBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 1),
  },
  ...getThemeStyles('TopBar', theme.palette.mode),
}));

const NavContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'calc(100% - 80px)',
    margin: '0 auto',
  },
  ...getThemeStyles('NavContainer', theme.palette.mode),
}));

const ContentArea = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  ...getThemeStyles('ContentArea', theme.palette.mode),
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  backdropFilter: 'blur(10px)',
  borderRadius: '50px',
  margin: '0 auto',
  width: 'fit-content',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5),
    width: '100%',
  },
  ...getThemeStyles('NavigationContainer', theme.palette.mode),
}));

const SlidingBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 5,
  left: 0,
  height: 'calc(100% - 10px)',
  borderRadius: '50px',
  backgroundColor: theme.palette.action.selected,
  transition: 'transform 0.3s ease, width 0.3s ease',
  ...getThemeStyles('SlidingBackground', theme.palette.mode),
}));

const NavButton = styled(Button)(({ theme, $active }) => ({
  color: $active ? theme.palette.primary.main : theme.palette.text.primary,
  margin: theme.spacing(0, 0.5),
  textTransform: 'capitalize',
  borderRadius: '50px',
  zIndex: 1,
  padding: theme.spacing(0.5, 2),
  backgroundColor: 'transparent',
  boxShadow: 'none',
  fontWeight: $active ? 600 : 600,
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '&:focus': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0, 0.25),
    fontSize: '0.8rem',
  },
  ...getThemeStyles('NavButton', theme.palette.mode),
}));

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const glow = keyframes`
  0% {
    box-shadow: 0 0 5px #007AFF;
  }
  50% {
    box-shadow: 0 0 20px #007AFF;
  }
  100% {
    box-shadow: 0 0 5px #007AFF;
  }
`;

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  transition: 'all 0.3s ease',
  '&:hover': {
    color: theme.palette.primary.main,
  },
  '&.rotate': {
    animation: `${rotate} 0.5s linear`,
  },
  '&.glow': {
    animation: `${glow} 1s ease-in-out`,
  },
}));

// Cache System
class MessageCache {
  constructor(maxSize = MESSAGE_CACHE_SIZE) {
    this.maxSize = maxSize;
    this.cache = new Map();
  }

  add(chatId, message) {
    if (!this.cache.has(chatId)) {
      this.cache.set(chatId, []);
    }
    const messages = this.cache.get(chatId);
    messages.push(message);
    
    if (messages.length > this.maxSize) {
      messages.shift();
    }
  }

  get(chatId) {
    return this.cache.get(chatId) || [];
  }

  clear() {
    this.cache.clear();
  }
}

// Resource Manager
class ResourceManager {
  constructor() {
    this.resources = new Set();
    this.cleanupFunctions = new Map();
  }

  register(resource, cleanup) {
    this.resources.add(resource);
    if (cleanup) {
      this.cleanupFunctions.set(resource, cleanup);
    }
  }

  release(resource) {
    const cleanup = this.cleanupFunctions.get(resource);
    if (cleanup) {
      cleanup();
      this.cleanupFunctions.delete(resource);
    }
    this.resources.delete(resource);
  }

  clearAll() {
    this.cleanupFunctions.forEach(cleanup => cleanup());
    this.cleanupFunctions.clear();
    this.resources.clear();
  }
}

const SidebarNavigation = React.memo(({ onNavigate, activeSection, showUpsInNav, showEduInNav }) => {
  const baseMenuItems = ['Home', 'Chat'];
  const menuItems = [
    ...baseMenuItems,
    ...(showUpsInNav ? ['Ups'] : []),
    ...(showEduInNav ? ['Edu'] : []),
    { icon: Plus, onClick: () => onNavigate('promo') }
  ];
  const [slideStyle, setSlideStyle] = useState({});
  const buttonRefs = useRef([]);
  const containerRef = useRef(null);
  const plusButtonRef = useRef(null);
  const lastNavigationTime = useRef(Date.now());
  const theme = useTheme();

  const updateSlideStyle = useCallback(() => {
    requestAnimationFrame(() => {
      const activeIndex = menuItems.findIndex(
        (item) => typeof item === 'string' && activeSection === item.toLowerCase()
      );
      
      if (activeIndex !== -1 && buttonRefs.current[activeIndex] && containerRef.current) {
        const activeButton = buttonRefs.current[activeIndex];
        const buttonRect = activeButton.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        const offsetLeft = buttonRect.left - containerRect.left;

        setSlideStyle({
          width: `${buttonRect.width}px`,
          transform: `translateX(${offsetLeft}px)`,
        });
      }
    });
  }, [activeSection, menuItems]);

  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(updateSlideStyle);
    };

    updateSlideStyle();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateSlideStyle]);

  useEffect(() => {
    let interval;
    if (plusButtonRef.current) {
      interval = setInterval(() => {
        if (plusButtonRef.current) { // Agregamos una verificación adicional
          plusButtonRef.current.classList.add('rotate', 'glow');
          setTimeout(() => {
            if (plusButtonRef.current) { // Y otra aquí
              plusButtonRef.current.classList.remove('rotate', 'glow');
            }
          }, 1000);
        }
      }, 30000);
    }
  
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const handleNavigationWithDebounce = useCallback((section) => {
    const now = Date.now();
    if (now - lastNavigationTime.current >= NAVIGATION_DEBOUNCE_TIME) {
      lastNavigationTime.current = now;
      onNavigate(section);
    }
  }, [onNavigate]);

  return (
    <NavigationContainer ref={containerRef}>
      <SlidingBackground style={slideStyle} />
      {menuItems.map((item, index) => 
        typeof item === 'string' ? (
          <NavButton
            key={item}
            ref={(el) => (buttonRefs.current[index] = el)}
            onClick={() => handleNavigationWithDebounce(item.toLowerCase())}
            disableRipple
            disableElevation
            disableFocusRipple
            $active={activeSection === item.toLowerCase()}
          >
            {item}
          </NavButton>
        ) : (
          <AnimatedIconButton
            key="plus"
            ref={plusButtonRef}
            onClick={item.onClick}
            size="small"
          >
            <item.icon size={18} />
          </AnimatedIconButton>
        )
      )}
    </NavigationContainer>
  );
});

const TopBarContent = React.memo(({ 
  handleBackgroundClick, 
  handleProfileClick, 
  user, 
  onNavigate, 
  activeSection, 
  showUpsInNav, 
  showEduInNav 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TopBar>
      <IconButton 
        onClick={handleBackgroundClick} 
        sx={{ 
          ...getThemeStyles('BackgroundButton', theme.palette.mode),
          padding: isMobile ? '4px' : '8px',
          marginRight: isMobile ? '4px' : '8px',
        }}
      >
        <Paintbrush size={isMobile ? 20 : 24} />
      </IconButton>
      <NavContainer>
        <SidebarNavigation 
          onNavigate={onNavigate} 
          activeSection={activeSection}
          showUpsInNav={showUpsInNav}
          showEduInNav={showEduInNav}
        />
      </NavContainer>
      <Avatar 
        src={user?.photoURL || "/path-to-default-image.jpg"} 
        alt={user?.displayName || "Profile"} 
        onClick={handleProfileClick}
        sx={{ 
          cursor: 'pointer', 
          width: isMobile ? 32 : 40, 
          height: isMobile ? 32 : 40,
        }}
      />
    </TopBar>
  );
});

const FullDashboard = ({ onLogout, user: initialUser, backgroundType: initialBackgroundType, onBackgroundChange }) => {
  // State Management
  const [activeSection, setActiveSection] = useState(() => {
    return localStorage.getItem('activeSection') || 'home';
  });
  const [user, setUser] = useState(initialUser);
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoadingChat, setIsLoadingChat] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showProfile, setShowProfile] = useState(false);
  const [backgroundSelectorAnchorEl, setBackgroundSelectorAnchorEl] = useState(null);
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [chatUpdateTrigger, setChatUpdateTrigger] = useState(0);
  const [mostRecentChatId, setMostRecentChatId] = useState(null);
  const [lastActivityTimestamp, setLastActivityTimestamp] = useState(Date.now());
  const [showUpsInNav, setShowUpsInNav] = useState(false);
  const [showEduInNav, setShowEduInNav] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  
  // Refs
  const inactivityTimerRef = useRef(null);
  const messageCache = useRef(new MessageCache());
  const resourceManager = useRef(new ResourceManager());
  const lastNavigationTime = useRef(Date.now());

  const backgroundImage = useMemo(() => ({
    aurora: "/images/background/aurora.png",
    curious: "/images/background/curious.png",
    edge: "/images/background/edge.png",
    eureka: "/images/background/eureka.png",
    hacker: "/images/background/hacker.png",
    horizon: "/images/background/horizon.png",
    ignite: "/images/background/ignite.png",
    mind: "/images/background/mind.png",
    neuro: "/images/background/neuro.png",
    odin: "/images/background/odin.png",
    python: "/images/background/python.png",
    vibe: "/images/background/vibe.png"
  }), []);

  const [backgroundType, setBackgroundType] = useState(() => {
    const validBackgroundTypes = ['system', 'light', 'dark', ...Object.keys(backgroundImage)];
    return validBackgroundTypes.includes(initialBackgroundType) ? initialBackgroundType : 'system';
  });

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const getThemeMode = useCallback((bgType) => {
    if (bgType === 'system') {
      return prefersDarkMode ? 'dark' : 'light';
    }
    return ['light', 'dark'].includes(bgType) ? bgType : 'light';
  }, [prefersDarkMode]);

  const theme = useMemo(() => createTheme({
    palette: {
      mode: getThemeMode(backgroundType),
    },
  }), [backgroundType, getThemeMode]);

  // Optimized Activity Timer Management
  const resetInactivityTimer = useCallback(() => {
    const now = Date.now();
    setLastActivityTimestamp(now);

    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }

    inactivityTimerRef.current = setTimeout(() => {
      if (activeSection === 'chat') {
        setActiveSection('home');
        localStorage.setItem('activeSection', 'home');
      }
    }, INACTIVITY_TIMEOUT);
  }, [activeSection]);

  // User and Authorization Effects
  useEffect(() => {
    setUser(initialUser);
    setIsUserAuthorized(isAuthorized(initialUser?.email));

    return () => {
      messageCache.current.clear();
      resourceManager.current.clearAll();
    };
  }, [initialUser]);

  // Chat History Loading Effect
  useEffect(() => {
    let isMounted = true;

    const loadChatHistory = async () => {
      if (!user) return;

      setIsLoadingChat(true);
      setErrorMessage('');

      try {
        const history = await getChatHistory(user.uid);
        
        if (isMounted) {
          setChatMessages(history);
          history.forEach(message => {
            messageCache.current.add(message.chatId, message);
          });
        }
      } catch (error) {
        if (isMounted) {
          setErrorMessage('Failed to load chat history. Please try again later.');
        }
      } finally {
        if (isMounted) {
          setIsLoadingChat(false);
        }
      }
    };

    loadChatHistory();

    return () => {
      isMounted = false;
    };
  }, [user]);

  // Background Preference Effect
  useEffect(() => {
    const loadUserPreference = async () => {
      if (!initialUser) return;

      try {
        const preference = await getUserBackgroundPreference(initialUser.uid);
        const validBackgroundTypes = ['system', 'light', 'dark', ...Object.keys(backgroundImage)];
        const newBackgroundType = validBackgroundTypes.includes(preference) ? preference : 'system';
        
        setBackgroundType(newBackgroundType);
        onBackgroundChange(newBackgroundType);
      } catch (error) {
        setBackgroundType('system');
        onBackgroundChange('system');
      }
    };
  
    loadUserPreference();
  }, [initialUser, onBackgroundChange, backgroundImage]);

  // Activity Monitoring Effect
  useEffect(() => {
    const handleUserActivity = () => {
      resetInactivityTimer();
    };

    const eventTypes = ['mousemove', 'keypress', 'click', 'scroll'];
    
    eventTypes.forEach(eventType => {
      window.addEventListener(eventType, handleUserActivity);
    });

    return () => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }

      eventTypes.forEach(eventType => {
        window.removeEventListener(eventType, handleUserActivity);
      });
    };
  }, [resetInactivityTimer]);

  // Navigation Handlers
  const handleNavigate = useCallback((section) => {
    const now = Date.now();
    if (now - lastNavigationTime.current >= NAVIGATION_DEBOUNCE_TIME) {
      lastNavigationTime.current = now;
      setActiveSection(section);
      setShowProfile(false);
      
      if (section === 'chat' && chatMessages.length === 0) {
        setChatMessages([]);
      }
      
      localStorage.setItem('activeSection', section);
      resetInactivityTimer();
    }
  }, [chatMessages.length, resetInactivityTimer]);

  const handleProfileClick = useCallback(() => {
    setShowProfile(true);
    setActiveSection('profile');
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  // Message Handling
  const handleSendMessage = useCallback(async (newMessageText, isUser = true) => {
    if (!isUser) return;

    try {
      const newChat = await createNewChat(user.uid);
      const chatId = newChat.id;
      
      // Add user message to cache immediately for optimistic updates
      const userMessage = { 
        text: newMessageText, 
        isUser: true, 
        id: Date.now(), 
        chatId 
      };
      messageCache.current.add(chatId, userMessage);
      
      const response = await sendMessageToAGT(newMessageText, user.uid, chatId);
      
      // Add bot response to cache
      const botMessage = { 
        text: response.reply, 
        isUser: false, 
        id: Date.now() + 1, 
        chatId 
      };
      messageCache.current.add(chatId, botMessage);
      
      setChatMessages(prev => [...prev, userMessage, botMessage]);
      setMostRecentChatId(chatId);
      setActiveSection('chat');
      resetInactivityTimer();
      setChatUpdateTrigger(prev => prev + 1);
    } catch (error) {
      setErrorMessage("Error sending message. Please try again.");
    }
  }, [user, resetInactivityTimer]);

  // Background Management
  const handleBackgroundClick = useCallback((event) => {
    setBackgroundSelectorAnchorEl(event.currentTarget);
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  const handleBackgroundClose = useCallback(() => {
    setBackgroundSelectorAnchorEl(null);
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  const handleBackgroundChange = useCallback(async (newBackgroundType) => {
    const validBackgroundTypes = ['system', 'light', 'dark', ...Object.keys(backgroundImage)];
    
    if (validBackgroundTypes.includes(newBackgroundType)) {
      try {
        await setUserBackgroundPreference(user.uid, newBackgroundType);
        setBackgroundType(newBackgroundType);
        onBackgroundChange(newBackgroundType);
      } catch (error) {
        setErrorMessage("Error saving background preference. Please try again.");
      }
    } else {
      setErrorMessage("Invalid background type.");
    }
    
    resetInactivityTimer();
  }, [user, onBackgroundChange, backgroundImage, resetInactivityTimer]);

  const getCurrentBackground = useCallback(() => {
    if (['light', 'dark', 'system'].includes(backgroundType)) {
      return null;
    }
    return backgroundImage[backgroundType];
  }, [backgroundType, backgroundImage]);

  // Additional Handlers
  const handleLogout = useCallback(() => {
    localStorage.setItem('activeSection', 'home');
    messageCache.current.clear();
    resourceManager.current.clearAll();
    onLogout();
  }, [onLogout]);

  const handleOpenAGTUps = useCallback(() => {
    setActiveSection('ups');
    setShowUpsInNav(true);
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  const handleOpenAGTEdu = useCallback(() => {
    setActiveSection('edu');
    setShowEduInNav(true);
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  const handleOpenAGTPromo = useCallback(() => {
    setActiveSection('promo');
    setShowPromo(true);
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  // Content Rendering
  const renderContent = useCallback(() => {
    switch (activeSection) {
      case 'home':
        return (
          <Box sx={{ flexGrow: 1, overflow: 'hidden', mb: 2, width: '100%' }}>
            <HomeInput 
              onSendMessage={handleSendMessage}
              onOpenAGTPromo={handleOpenAGTPromo}
            />
          </Box>
        );
      case 'chat':
        return (
          <Box sx={{ 
            flexGrow: 1, 
            overflowY: 'auto', 
            width: '100%', 
            height: '100%',
            display: 'flex', 
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
              margin: 0,
              marginTop: '-1px',
            }
          }}>
            <ChatViewNest 
              key={chatUpdateTrigger}
              user={user}
              isAuthorized={isUserAuthorized}
              onSendMessage={handleSendMessage}
              isLimited={false}
              initialView={chatMessages.length === 0}
              messages={chatMessages}
              isLoading={isLoadingChat}
              onNavigate={handleNavigate}
              themeMode={theme.palette.mode}
              mostRecentChatId={mostRecentChatId}
            />
          </Box>
        );
      case 'profile':
        return (
          <ProfileDashboard 
            user={user}
            onLogout={handleLogout}
            backgroundType={backgroundType}
          />
        );
      case 'ups':
        return <AGTUps />;
      case 'edu':
        return <AGTEdu />;
      case 'promo':
        return (
          <AGTPromo 
            onOpenAGTUps={handleOpenAGTUps} 
            onOpenAGTEdu={handleOpenAGTEdu} 
          />
        );
      default:
        return null;
    }
  }, [
    activeSection,
    user,
    isUserAuthorized,
    handleSendMessage,
    chatMessages,
    isLoadingChat,
    handleNavigate,
    theme,
    handleLogout,
    backgroundType,
    chatUpdateTrigger,
    mostRecentChatId,
    handleOpenAGTUps,
    handleOpenAGTEdu,
    handleOpenAGTPromo
  ]);

  return (
    <ThemeProvider theme={theme}>
      <FixedContainer style={{ 
        backgroundImage: getCurrentBackground() ? `url(${getCurrentBackground()})` : 'none',
        backgroundColor: getCurrentBackground() 
          ? 'transparent' 
          : (backgroundType === 'system'
              ? (prefersDarkMode ? '#171717' : '#FFFFFF')
              : (backgroundType === 'light' ? '#FFFFFF' : '#171717'))
      }}>
        <StyledWindowBar>
          <TopBarContent 
            handleBackgroundClick={handleBackgroundClick}
            handleProfileClick={handleProfileClick}
            user={user}
            onNavigate={handleNavigate}
            activeSection={activeSection}
            showUpsInNav={showUpsInNav}
            showEduInNav={showEduInNav}
          />
          <ContentArea>
            <Fade in={true} timeout={300}>
              <Box sx={{ 
                height: '100%', 
                width: '100%',
                display: 'flex', 
                flexDirection: 'column',
                [theme.breakpoints.down('sm')]: {
                  margin: 0,
                }
              }}>
                {renderContent()}
              </Box>
            </Fade>
          </ContentArea>
        </StyledWindowBar>
        <BackgroundSelector
          anchorEl={backgroundSelectorAnchorEl}
          open={Boolean(backgroundSelectorAnchorEl)}
          onClose={handleBackgroundClose}
          onBackgroundChange={handleBackgroundChange}
          currentBackground={backgroundType}
        />
        <Snackbar 
          open={!!errorMessage} 
          autoHideDuration={6000} 
          onClose={() => setErrorMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setErrorMessage('')} 
            severity="error" 
            sx={{ width: '100%' }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </FixedContainer>
    </ThemeProvider>
  );
};

export default React.memo(FullDashboard);