import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Typography,
  Card as MuiCard,
  Autocomplete,
  ClickAwayListener,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ArrowUp,
  MapPin,
  Sun,
  Search,
  Video,
  GraduationCap,
  DollarSign,
  X,
  Clock,
  Coffee,
  Book,
  Music,
  ShoppingCart,
  Briefcase,
  Utensils,
  Camera,
  Plane,
  Car,
  Smartphone,
  Heart,
  Zap,
  Umbrella,
  Calendar,
  Users,
  Scissors,
  Palette,
  Gift
} from 'lucide-react';
import Typed from 'typed.js';
import debounce from 'lodash/debounce';
import { getThemeStyles } from '../themes/themeConfig';
import { getSuggestions, TrendingSuggestions } from '../data/suggestions';

// Constants
const TYPED_STRINGS = [
  'Think different with AGT.',
  'Empower your curiosity.',
  'Experience intelligence.',
  'Innovation at your command.',
  'Simply powerful.',
  'Unlock possibilities with AGT.',
  'Your ideas, enhanced.',
  'Seamless intelligence.',
  'Discover more with less effort.',
  'AGT: Simplicity meets power.',
];

const AGT_CARDS = [
  { Icon: GraduationCap, text: "AGT Edu", color: "#4B5563" },
  { Icon: DollarSign, text: "AGT Ups", color: "#10B981" }
];

const PROMPT_CARDS = [
  { Icon: MapPin, text: '¿Cómo llego rápido a este lugar?', color: '#3B82F6' },
  { Icon: Sun, text: '¿Cuál es el clima actual?', color: '#F59E0B' },
  { Icon: Search, text: 'Necesito información urgente sobre...', color: '#10B981' },
  { Icon: Video, text: 'Muéstrame un video viral ahora', color: '#EF4444' },
  { Icon: Clock, text: '¿Cuánto tiempo me queda para...?', color: '#6366F1' },
  { Icon: Coffee, text: '¿Dónde puedo tomar un café ya?', color: '#8B4513' },
  { Icon: Book, text: 'Resumen rápido de este libro', color: '#1F2937' },
  { Icon: Music, text: '¿Cuál es la canción del momento?', color: '#7C3AED' },
  { Icon: ShoppingCart, text: 'Necesito comprar esto ahora', color: '#059669' },
  { Icon: Briefcase, text: 'Prepárame para mi entrevista', color: '#DC2626' },
  { Icon: Utensils, text: '¿Qué puedo cocinar en 15 minutos?', color: '#D97706' },
  { Icon: Camera, text: 'Cómo tomar la mejor foto aquí', color: '#4338CA' },
  { Icon: Plane, text: '¿Hay vuelos baratos hoy?', color: '#0EA5E9' },
  { Icon: Car, text: 'Necesito un taxi ahora mismo', color: '#FBBF24' },
  { Icon: Smartphone, text: 'Arregla mi teléfono, ¡es urgente!', color: '#14B8A6' },
  { Icon: Heart, text: 'Ideas para una cita romántica ya', color: '#EC4899' },
  { Icon: Zap, text: 'Dame energía para terminar esto', color: '#F59E0B' },
  { Icon: DollarSign, text: '¿Cómo ahorrar dinero rápido?', color: '#10B981' },
  { Icon: Gift, text: 'Necesito un regalo de último minuto', color: '#6366F1' },
  { Icon: Umbrella, text: '¿Va a llover hoy?', color: '#3B82F6' },
  { Icon: Calendar, text: 'Organiza mi agenda para hoy', color: '#8B5CF6' },
  { Icon: Users, text: 'Encuentra un grupo para...', color: '#EF4444' },
  { Icon: Scissors, text: '¿Dónde puedo cortarme el pelo ya?', color: '#D97706' },
  { Icon: Palette, text: 'Dame ideas creativas rápidas', color: '#EC4899' }
];

// Styled Components
const StyledComponents = {
  CenteredContainer: styled(Box)(({ theme }) => ({
    ...getThemeStyles('CenteredContainer', theme.palette.mode),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  })),

  ContentWrapper: styled(Box)(({ theme }) => ({
    ...getThemeStyles('ContentWrapper', theme.palette.mode),
    width: '100%',
    maxWidth: '800px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  })),

  StyledForm: styled('form')(({ theme }) => ({
    ...getThemeStyles('StyledForm', theme.palette.mode),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    position: 'relative',
  })),

  StyledTextField: styled(TextField)(({ theme }) => ({
    ...getThemeStyles('StyledTextField', theme.palette.mode),
    flexGrow: 1,
    margin: '10px 0',
    '& .MuiInputBase-root': {
      maxWidth: '100%',
      width: '100%',
    },
  })),

  StyledAutocomplete: styled(Autocomplete)(({ theme }) => ({
    ...getThemeStyles('StyledAutocomplete', theme.palette.mode),
    width: '100%',
    '& .MuiInputBase-input': {
      paddingRight: '70px !important'
    }
  })),
  
  SendButton: styled('div')(({ theme }) => ({
    ...getThemeStyles('SendButton', theme.palette.mode),
    cursor: 'pointer',
    position: 'absolute',
    right: '30px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
  })),

  ClearButton: styled('div')(({ theme }) => ({
    ...getThemeStyles('ClearButton', theme.palette.mode),
    cursor: 'pointer',
    position: 'absolute',
    right: '70px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
  })),

  SectionsContainer: styled(Box)(({ theme }) => ({
    ...getThemeStyles('SectionsContainer', theme.palette.mode),
    display: 'flex',
    width: '100%',
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  })),

  Section: styled(Box)(({ theme }) => ({
    ...getThemeStyles('Section', theme.palette.mode),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gap: theme.spacing(1),
    },
  })),

  SectionTitle: styled(Typography)(({ theme, isMobile }) => ({
    ...getThemeStyles('SectionTitle', theme.palette.mode),
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'left',
    fontSize: isMobile ? '0.9rem' : '1.2rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.5),
    },
  })),

  CardsRow: styled(Box)(({ theme }) => ({
    ...getThemeStyles('CardsRow', theme.palette.mode),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%',
  })),

  CustomCard: styled(MuiCard)(({ theme }) => ({
    ...getThemeStyles('CustomCard', theme.palette.mode),
    width: '100%',
    cursor: 'pointer',
    borderRadius: '30px',
    transition: 'box-shadow 0.3s, border 0.3s',
    boxShadow: 'none',
  })),

  CustomCardContent: styled(Box)(({ theme }) => ({
    ...getThemeStyles('CustomCardContent', theme.palette.mode),
    width: '100%',
    padding: '12px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
  })),
};

// Memoized Components
const PromptCard = React.memo(({ Icon, text, color, onClick, isMobile }) => (
  <StyledComponents.CustomCard onClick={onClick}>
    <StyledComponents.CustomCardContent>
      <Icon size={24} color={color} />
      <Typography variant="body2">{text}</Typography>
    </StyledComponents.CustomCardContent>
  </StyledComponents.CustomCard>
));

const CardRow = React.memo(({ cardData, onCardClick, isMobile }) => (
  <StyledComponents.CardsRow>
    {cardData.map((card, index) => (
      <PromptCard
        key={`${card.text}-${index}`}
        {...card}
        onClick={() => onCardClick(card.text)}
        isMobile={isMobile}
      />
    ))}
  </StyledComponents.CardsRow>
));

const InputField = React.memo(({ 
  state,
  onStateUpdate,
  onSubmit,
  inputRef
}) => {
  // Manejamos el input de forma inmediata
  const handleInputChange = useCallback((event, newValue) => {
    // Actualización inmediata del input
    onStateUpdate({
      input: newValue || '',
      showTrending: !newValue,
    });

    // Actualizamos las sugerencias con debounce
    debouncedUpdateSuggestions(newValue);
  }, [onStateUpdate]);

  // Creamos el debounce para las sugerencias
  const debouncedUpdateSuggestions = useMemo(
    () => debounce((newValue) => {
      if (newValue) {
        const suggestions = getSuggestions(newValue);
        onStateUpdate({
          filteredSuggestions: suggestions,
          showSuggestions: true
        });
      } else {
        onStateUpdate({
          filteredSuggestions: [],
          showSuggestions: false
        });
      }
    }, 50), // Reducimos el tiempo de debounce a 200ms
    [onStateUpdate]
  );

  // Limpiamos el debounce al desmontar
  useEffect(() => {
    return () => {
      debouncedUpdateSuggestions.cancel();
    };
  }, [debouncedUpdateSuggestions]);

  const handleClear = useCallback(() => {
    onStateUpdate({
      input: '',
      showTrending: true,
      showSuggestions: false,
      filteredSuggestions: []
    });
  }, [onStateUpdate]);

  return (
    <StyledComponents.StyledAutocomplete
      freeSolo
      options={state.filteredSuggestions}
      inputValue={state.input}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <StyledComponents.StyledTextField
          {...params}
          inputRef={inputRef}
          placeholder="Ask AGT"
          variant="outlined"
          fullWidth
          onFocus={() => {
            if (state.isInitialFocus) {
              onStateUpdate({ isInitialFocus: false });
              return;
            }
            if (!state.input) {
              onStateUpdate({ showTrending: true });
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {state.input && (
                  <StyledComponents.ClearButton onClick={handleClear}>
                    <X size={20} />
                  </StyledComponents.ClearButton>
                )}
                <StyledComponents.SendButton onClick={onSubmit}>
                  <ArrowUp size={24} />
                </StyledComponents.SendButton>
              </>
            ),
          }}
        />
      )}
      open={state.showSuggestions}
      onOpen={() => onStateUpdate({ showSuggestions: true })}
      onClose={() => onStateUpdate({ showSuggestions: false })}
      autoComplete="off"
      autoHighlight={false}
      disableListWrap
      clearOnBlur={false}
      selectOnFocus={false}
      blurOnSelect={false}
      filterOptions={(x) => x}
    />
  );
});

const HomeLimited = ({ onSendMessage, onAgtEduClick, onAgtUpsClick, onAuthRequired }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, setState] = useState({
    input: '',
    showTrending: false,
    showSuggestions: false,
    filteredSuggestions: [],
    isInitialFocus: true,
    randomPromptCards: [],
  });

  const inputRef = useRef(null);
  const elRef = useRef(null);
  const typedRef = useRef(null);

  const updateState = useCallback((updates) => {
    setState(prev => ({
      ...prev,
      ...updates
    }));
  }, []);

  const handleSubmit = useCallback((e) => {
    e?.preventDefault();
    if (state.input.trim()) {
      onAuthRequired(state.input);
      updateState({
        input: '',
        showTrending: false,
        showSuggestions: false
      });
    }
  }, [state.input, onAuthRequired, updateState]);

  const cardActions = useMemo(() => ({
    "AGT Edu": onAgtEduClick,
    "AGT Ups": onAgtUpsClick,
    default: (text) => {
      updateState({
        input: text,
        showTrending: false,
        showSuggestions: false
      });
    }
  }), [onAgtEduClick, onAgtUpsClick, updateState]);

  const handleCardClick = useCallback((text) => {
    (cardActions[text] || cardActions.default)(text);
  }, [cardActions]);

  useEffect(() => {
    // Initialize Typed
    const options = {
      strings: TYPED_STRINGS,
      typeSpeed: 40,
      backSpeed: 20,
      startDelay: 500,
      backDelay: 7000,
      loop: true,
      shuffle: true,
      cursorChar: '|',
    };
    
    typedRef.current = new Typed(elRef.current, options);
    
    // Focus input and set random cards
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const shuffled = [...PROMPT_CARDS].sort(() => 0.5 - Math.random());
    updateState({ randomPromptCards: shuffled.slice(0, 2) });

    return () => {
      if (typedRef.current) {
        typedRef.current.destroy();
      }
    };
  }, [updateState]);

  const handleClickAway = useCallback(() => {
    updateState({
      showTrending: false,
      showSuggestions: false
    });
  }, [updateState]);

  const MemoizedTrendingSuggestions = useMemo(() => React.memo(TrendingSuggestions), []);

  return (
    <StyledComponents.CenteredContainer>
      <StyledComponents.ContentWrapper>
      <Typography 
          variant="h2" 
          gutterBottom 
          align="center" 
          sx={{ fontSize: isMobile ? '1.5rem' : '2.5rem' }}
        >
          <span ref={elRef} />
        </Typography>
        
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box width="100%" position="relative">
            <StyledComponents.StyledForm onSubmit={handleSubmit}>
              <InputField
                state={state}
                onStateUpdate={updateState}
                onSubmit={handleSubmit}
                inputRef={inputRef}
              />
            </StyledComponents.StyledForm>
            
            {state.showTrending && !state.input && (
              <Box 
                position="absolute" 
                width="100%" 
                zIndex={1} 
                className="trending-suggestions"
              >
                <MemoizedTrendingSuggestions onSuggestionClick={handleCardClick} />
              </Box>
            )}
          </Box>
        </ClickAwayListener>

        <StyledComponents.SectionsContainer>
          <StyledComponents.Section>
            <StyledComponents.SectionTitle variant="h5" isMobile={isMobile}>
              Explore
            </StyledComponents.SectionTitle>
            <CardRow 
              cardData={AGT_CARDS}
              onCardClick={handleCardClick}
              isMobile={isMobile}
            />
          </StyledComponents.Section>

          <StyledComponents.Section>
            <StyledComponents.SectionTitle variant="h5" isMobile={isMobile}>
              Ask
            </StyledComponents.SectionTitle>
            <CardRow 
              cardData={state.randomPromptCards}
              onCardClick={handleCardClick}
              isMobile={isMobile}
            />
          </StyledComponents.Section>
        </StyledComponents.SectionsContainer>
      </StyledComponents.ContentWrapper>
    </StyledComponents.CenteredContainer>
  );
};

// PropTypes
HomeLimited.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
  onAgtEduClick: PropTypes.func.isRequired,
  onAgtUpsClick: PropTypes.func.isRequired,
  onAuthRequired: PropTypes.func.isRequired,
};

PromptCard.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

CardRow.propTypes = {
  cardData: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.elementType.isRequired,
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  onCardClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

InputField.propTypes = {
  state: PropTypes.shape({
    input: PropTypes.string.isRequired,
    showTrending: PropTypes.bool.isRequired,
    showSuggestions: PropTypes.bool.isRequired,
    filteredSuggestions: PropTypes.array.isRequired,
    isInitialFocus: PropTypes.bool.isRequired,
  }).isRequired,
  onStateUpdate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
};

export default React.memo(HomeLimited);