// Lista de correos electrónicos autorizados
const authorizedEmails = [
    'annai.arlette@gmail.com',
    'masgameplays8@gmail.com',
    'fh601950@gmail.com',
    'sebaduranb@icloud.com',
    'orellanaesteban74@gmail.com',
    'sl600532@gmail.com',
    'matias.cabanas.04@gmail.com',
    'franciscabelensepulvedafuentes@gmail.com',
    'ta_barom@icloud.com',
    'oscargaj012@gmail.com',
    'king.moreira.300@gmail.com',
    'estebanquevedo789@gmail.com',
    'camila.andrea0926@gmail.com',
    'alan@euristikk.com',
    // Añade aquí más correos electrónicos
  ];
  
  // Función para verificar si un correo electrónico está autorizado
  export const isAuthorized = (email) => {
    return authorizedEmails.includes(email);
  };
  
  export default {
    isAuthorized,
  };