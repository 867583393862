import React, { useState, useEffect, useMemo } from 'react';
import { Popover, Box, IconButton, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Sun, Moon, Monitor } from 'lucide-react';
import { getThemeStyles } from '../themes/themeConfig';

const BackgroundButton = styled(IconButton)(({ theme, selected }) => ({
  width: 40,
  height: 40,
  padding: 0,
  margin: 4,
  borderRadius: '50%',
  border: selected ? `2px solid ${theme.palette.primary.main}` : 'none',
  overflow: 'hidden',
  '&:hover': {
    opacity: 0.8,
  },
  position: 'relative',
  ...getThemeStyles('BackgroundButton', theme.palette.mode),
}));

// Commented out BackgroundImage styled component as it's not needed for system/light/dark
/*
const BackgroundImage = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  transition: 'opacity 0.3s ease-in-out',
});
*/

const backgroundGroups = [
  {
    title: 'Typical',
    backgrounds: [
      { name: 'System', type: 'system', icon: Monitor },
      { name: 'Light', type: 'light', icon: Sun },
      { name: 'Dark', type: 'dark', icon: Moon },
    ],
  },
  // Commented out other background groups
  /*
  {
    title: 'Popular',
    backgrounds: [
      { name: 'Eureka', type: 'eureka', image: "/images/IconBackground/eureka.png" },
      { name: 'Neuro', type: 'neuro', image: "/images/IconBackground/neuro.png" },
      { name: 'Python', type: 'python', image: "/images/IconBackground/python.png" },
      { name: 'Curious', type: 'curious', image: "/images/IconBackground/curious.png" },
    ],
  },
  {
    title: 'Others',
    backgrounds: [
      { name: 'Aurora', type: 'aurora', image: "/images/IconBackground/aurora.png" },
      { name: 'Edge', type: 'edge', image: "/images/IconBackground/edge.png" },
      { name: 'Hacker', type: 'hacker', image: "/images/IconBackground/hacker.png" },
      { name: 'Horizon', type: 'horizon', image: "/images/IconBackground/horizon.png" },
      { name: 'Ignite', type: 'ignite', image: "/images/IconBackground/ignite.png" },
      { name: 'Mind', type: 'mind', image: "/images/IconBackground/mind.png" },
      { name: 'Odin', type: 'odin', image: "/images/IconBackground/odin.png" },
      { name: 'Vibe', type: 'vibe', image: "/images/IconBackground/vibe.png" },
    ],
  },
  */
];

const BackgroundSelector = ({ anchorEl, open, onClose, onBackgroundChange, currentBackground }) => {
  const theme = useTheme();
  // Removed state and effects related to image loading
  // const [loadedImages, setLoadedImages] = useState({});
  // const [hoveredBackground, setHoveredBackground] = useState(null);

  const allBackgrounds = useMemo(() => backgroundGroups.flatMap(group => group.backgrounds), []);

  // Removed preloadedImages logic

  const handleBackgroundChange = (bgType) => {
    onBackgroundChange(bgType);
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          width: '300px',
          backgroundColor: 'rgba(128, 128, 128, 0.3)',
          backdropFilter: 'blur(12px)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          padding: '15px',
        },
      }}
    >
      <Box
        sx={{
          maxHeight: 400,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-button': {
            display: 'none',
            width: 0,
            height: 0,
          },
          scrollbarWidth: 'thin',
          scrollbarColor: 'rgba(255, 255, 255, 0.5) transparent',
        }}
      >
        <Typography variant="h6" sx={{ color: 'white', marginBottom: 2, textAlign: 'center' }}>
          Choose your Background
        </Typography>
        {backgroundGroups.map((group) => (
          <Box key={group.title} sx={{ marginBottom: 2 }}>
            <Typography variant="subtitle1" sx={{ color: 'white', marginBottom: 1 }}>
              {group.title}
            </Typography>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2 }}>
              {group.backgrounds.map((bg) => (
                <Box key={bg.type} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <BackgroundButton
                    onClick={() => handleBackgroundChange(bg.type)}
                    selected={currentBackground === bg.type}
                  >
                    <bg.icon sx={{ color: 'white' }} />
                  </BackgroundButton>
                  <Typography
                    variant="caption"
                    sx={{ color: 'white', fontSize: '0.7rem', marginTop: '4px' }}
                  >
                    {bg.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default BackgroundSelector;