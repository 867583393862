import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Typography,
  Card as MuiCard,
  Autocomplete,
  ClickAwayListener,
  useMediaQuery,
  useTheme,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import debounce from 'lodash/debounce';
import {
  ArrowUp,
  MapPin,
  Sun,
  Search,
  Video,
  GraduationCap,
  DollarSign,
  X,
  Clock,
  Coffee,
  Book,
  Music,
  ShoppingCart,
  Briefcase,
  Utensils,
  Camera,
  Plane,
  Car,
  Smartphone,
  Heart,
  Zap,
  Umbrella,
  Calendar,
  Users,
  Scissors,
  Palette,
  Gift
} from 'lucide-react';
import Typed from 'typed.js';
import { getThemeStyles } from '../themes/themeConfig';
import { getSuggestions, TrendingSuggestions } from '../data/suggestions';

// Constants
const TYPED_STRINGS = [
  'Think different with AGT.',
  'Empower your curiosity.',
  'Experience intelligence.',
  'Innovation at your command.',
  'Simply powerful.',
  'Unlock possibilities with AGT.',
  'Your ideas, enhanced.',
  'Seamless intelligence.',
  'Discover more with less effort.',
  'AGT: Simplicity meets power.',
];

const TYPED_OPTIONS = {
  typeSpeed: 40,
  backSpeed: 20,
  startDelay: 500,
  backDelay: 7000,
  loop: true,
  shuffle: true,
  cursorChar: '|',
};

const AGT_CARDS = [
  { Icon: GraduationCap, text: "AGT Edu", color: "#4B5563", comingSoon: false },
  { Icon: DollarSign, text: "AGT Ups", color: "#10B981", comingSoon: false }
];

const PROMPT_CARDS = [
  { Icon: MapPin, text: '¿Cómo llego rápido a este lugar?', color: '#3B82F6', comingSoon: true },
  { Icon: Sun, text: '¿Cuál es el clima actual?', color: '#F59E0B', comingSoon: true },
  { Icon: Search, text: 'Necesito información urgente sobre...', color: '#10B981', comingSoon: false },
  { Icon: Video, text: 'Muéstrame un video viral ahora', color: '#EF4444', comingSoon: true },
  { Icon: Clock, text: '¿Cuánto tiempo me queda para...?', color: '#6366F1', comingSoon: true },
  { Icon: Coffee, text: '¿Dónde puedo tomar un café ya?', color: '#8B4513', comingSoon: true },
  { Icon: Book, text: 'Resumen rápido de este libro', color: '#1F2937', comingSoon: true },
  { Icon: Music, text: '¿Cuál es la canción del momento?', color: '#7C3AED', comingSoon: true },
  { Icon: ShoppingCart, text: 'Necesito comprar esto ahora', color: '#059669', comingSoon: true },
  { Icon: Briefcase, text: 'Prepárame para mi entrevista', color: '#DC2626', comingSoon: false },
  { Icon: Utensils, text: '¿Qué puedo cocinar en 15 minutos?', color: '#D97706', comingSoon: false },
  { Icon: Camera, text: 'Cómo tomar la mejor foto aquí', color: '#4338CA', comingSoon: true },
  { Icon: Plane, text: '¿Hay vuelos baratos hoy?', color: '#0EA5E9', comingSoon: true },
  { Icon: Car, text: 'Necesito un taxi ahora mismo', color: '#FBBF24', comingSoon: true },
  { Icon: Smartphone, text: 'Arregla mi teléfono, ¡es urgente!', color: '#14B8A6', comingSoon: true },
  { Icon: Heart, text: 'Ideas para una cita romántica ya', color: '#EC4899', comingSoon: false },
  { Icon: Zap, text: 'Dame energía para terminar esto', color: '#F59E0B', comingSoon: false },
  { Icon: DollarSign, text: '¿Cómo ahorrar dinero rápido?', color: '#10B981', comingSoon: true },
  { Icon: Gift, text: 'Necesito un regalo de último minuto', color: '#6366F1', comingSoon: false },
  { Icon: Umbrella, text: '¿Va a llover hoy?', color: '#3B82F6', comingSoon: true },
  { Icon: Calendar, text: 'Organiza mi agenda para hoy', color: '#8B5CF6', comingSoon: false },
  { Icon: Users, text: 'Encuentra un grupo para...', color: '#EF4444', comingSoon: true },
  { Icon: Scissors, text: '¿Dónde puedo cortarme el pelo ya?', color: '#D97706', comingSoon: true },
  { Icon: Palette, text: 'Dame ideas creativas rápidas', color: '#EC4899', comingSoon: false }
];

const WAITING_PHASES = [
  ["Analyzing input data", "Processing information", "Gathering relevant parameters"],
  ["Identifying key variables", "Establishing causal relationships", "Mapping data structures"],
  ["Formulating hypotheses", "Predicting outcomes", "Simulating scenarios"],
  ["Checking logical consistency", "Evaluating hypotheses", "Validating models"],
  ["Integrating new information", "Finalizing conclusions", "Preparing comprehensive response"]
];

// Animation keyframes
const glowAnimation = keyframes`
  0% { opacity: 0; filter: blur(10px); }
  50% { opacity: 1; filter: blur(0px); }
  100% { opacity: 0; filter: blur(10px); }
`;

// Styled Components
const CenteredContainer = styled(Box)(({ theme }) => ({
  ...getThemeStyles('CenteredContainer', theme.palette.mode),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2),
  boxSizing: 'border-box',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  ...getThemeStyles('ContentWrapper', theme.palette.mode),
  width: '100%',
  maxWidth: '800px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledForm = styled('form')(({ theme }) => ({
  ...getThemeStyles('StyledForm', theme.palette.mode),
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  position: 'relative',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  ...getThemeStyles('StyledTextField', theme.palette.mode),
  flexGrow: 1,
  margin: '10px 0',
  '& .MuiInputBase-root': {
    maxWidth: '100%',
    width: '100%',
    position: 'relative',
  },
  '& .MuiInputBase-input': {
    maxHeight: '250px',
    overflowY: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    paddingRight: '60px !important', // Aseguramos que este padding se aplique
    marginRight: '100px', // Agregamos margen para el área de scroll
  },
  // Estilos específicos para el scrollbar
  '& .MuiInputBase-input::-webkit-scrollbar': {
    width: '8px',
    position: 'absolute',
    right: '60px', // Posicionamos el scrollbar
  },
  '& .MuiInputBase-input::-webkit-scrollbar-track': {
    background: 'transparent',
    marginRight: '60px', // Margen para el track del scrollbar
  },
  '& .MuiInputBase-input::-webkit-scrollbar-thumb': {
    background: theme.palette.mode === 'dark' ? '#555' : '#ccc',
    borderRadius: '4px',
    marginRight: '60px', // Margen para el thumb del scrollbar
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  ...getThemeStyles('StyledAutocomplete', theme.palette.mode),
  width: '100%',
  '& .MuiInputBase-input': {
    paddingRight: '60px !important', // Aumentado para dar más espacio
  },
  // Aseguramos que los botones estén en la posición correcta
  '& .MuiAutocomplete-endAdornment': {
    right: '60px !important', // Forzamos la posición
  },
  // Contenedor del input
  '& .MuiOutlinedInput-root': {
    paddingRight: '20px !important', // Espacio para los botones
  },
}));

// Modificamos los botones para asegurar su posición
const SendButton = styled(IconButton)(({ theme }) => ({
  ...getThemeStyles('SendButton', theme.palette.mode),
  position: 'absolute',
  right: '14px',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 10, // Aumentamos el z-index
  padding: '8px',
}));

const ClearButton = styled(IconButton)(({ theme }) => ({
  ...getThemeStyles('ClearButton', theme.palette.mode),
  position: 'absolute',
  right: '70px',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 10, // Aumentamos el z-index
  padding: '8px',
}));

// Contenedor principal modificado
const InputContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  // Estilo específico para el área de contenido
  '& .MuiInputBase-root': {
    '& > textarea': {
      paddingRight: '120px !important',
      boxSizing: 'border-box',
    }
  },
  // Forzamos el margen del área de scroll
  '& .MuiOutlinedInput-root': {
    '& > textarea::-webkit-scrollbar': {
      marginRight: '120px !important',
    }
  }
}));

const SectionsContainer = styled(Box)(({ theme }) => ({
  ...getThemeStyles('SectionsContainer', theme.palette.mode),
  display: 'flex',
  width: '100%',
  gap: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const Section = styled(Box)(({ theme }) => ({
  ...getThemeStyles('Section', theme.palette.mode),
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    gap: theme.spacing(1),
  },
}));

const SectionTitle = styled(Typography)(({ theme, isMobile }) => ({
  ...getThemeStyles('SectionTitle', theme.palette.mode),
  marginBottom: theme.spacing(1),
  fontWeight: 'bold',
  width: '100%',
  textAlign: 'left',
  fontSize: isMobile ? '0.9rem' : '1.2rem',
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(0.5),
  },
}));

const CardsRow = styled(Box)(({ theme }) => ({
  ...getThemeStyles('CardsRow', theme.palette.mode),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',
}));

const CustomCard = styled(MuiCard)(({ theme }) => ({
  ...getThemeStyles('CustomCard', theme.palette.mode),
  width: '100%',
  cursor: 'pointer',
  borderRadius: '30px',
  transition: 'box-shadow 0.3s, border 0.3s',
  boxShadow: 'none',
}));

const CustomCardContent = styled(Box)(({ theme }) => ({
  ...getThemeStyles('CustomCardContent', theme.palette.mode),
  width: '100%',
  padding: '12px',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '10px',
  position: 'relative',
}));

const ComingSoonText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  fontSize: '0.75rem',
  fontStyle: 'italic',
  color: theme.palette.text.secondary,
}));

const WaitingMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontStyle: 'italic',
  color: theme.palette.text.secondary,
  textAlign: 'center',
  width: '100%',
  animation: `${glowAnimation} 3s ease-in-out infinite`,
}));

const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

// Component for input field
const InputField = React.memo(({ 
  state, 
  isWaiting, 
  inputRef, 
  onSubmit, 
  onStateUpdate, 
  onSuggestionsUpdate 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleInputChange = useCallback((event, newValue) => {
    onStateUpdate({ input: newValue || '' });
    onSuggestionsUpdate(newValue);
  }, [onStateUpdate, onSuggestionsUpdate]);

  const handleClear = useCallback(() => {
    onStateUpdate({
      input: '',
      showTrending: true,
      showSuggestions: false,
      filteredSuggestions: []
    });
  }, [onStateUpdate]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      if (isMobile) {
        // En móviles, solo envía si se presiona Shift+Enter
        if (event.shiftKey) {
          event.preventDefault();
          onSubmit(event);
        }
      } else {
        // En desktop, Enter envía el mensaje y Shift+Enter hace nueva línea
        if (!event.shiftKey) {
          event.preventDefault();
          onSubmit(event);
        }
      }
    }
  }, [isMobile, onSubmit]);

  return (
    <StyledAutocomplete
      freeSolo
      options={state.filteredSuggestions}
      inputValue={state.input}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          inputRef={inputRef}
          placeholder="Ask AGT"
          variant="outlined"
          fullWidth
          multiline
          maxRows={10}
          disabled={isWaiting}
          onKeyDown={handleKeyDown}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {state.input && !isWaiting && (
                  <ClearButton
                    onClick={handleClear}
                    size="small"
                    aria-label="clear"
                  >
                    <X size={20} />
                  </ClearButton>
                )}
                <SendButton
                  onClick={onSubmit}
                  disabled={isWaiting || !state.input.trim()}
                  size="small"
                  aria-label="send"
                >
                  {isWaiting ? (
                    <LoadingIndicator size={24} />
                  ) : (
                    <ArrowUp size={24} />
                  )}
                </SendButton>
              </>
            ),
          }}
        />
      )}
      open={state.showSuggestions}
      onOpen={() => onStateUpdate({ showSuggestions: true })}
      onClose={() => onStateUpdate({ showSuggestions: false })}
      disablePortal={isWaiting}
      disabled={isWaiting}
    />
  );
});

// Card Components
const PromptCard = React.memo(({ Icon, text, color, onClick, isMobile, comingSoon }) => (
  <CustomCard 
    onClick={comingSoon ? undefined : onClick}
    style={{ cursor: comingSoon ? 'default' : 'pointer' }}
  >
    <CustomCardContent>
      <Icon size={24} color={color} />
      <Typography variant="body2">{text}</Typography>
      {comingSoon && (
        <ComingSoonText>
          Próximamente
        </ComingSoonText>
      )}
    </CustomCardContent>
  </CustomCard>
));

const CardRow = React.memo(({ cardData, onCardClick, isMobile }) => (
  <CardsRow>
    {cardData.map((card, index) => (
      <PromptCard
        key={`${card.text}-${index}`}
        {...card}
        onClick={() => onCardClick(card.text, card.comingSoon)}
        isMobile={isMobile}
      />
    ))}
  </CardsRow>
));

// Section Component
const SectionComponent = React.memo(({ title, cards, onCardClick, isMobile }) => (
  <Section>
    <SectionTitle variant="h5" isMobile={isMobile}>
      {title}
    </SectionTitle>
    <CardRow 
      cardData={cards}
      onCardClick={onCardClick}
      isMobile={isMobile}
    />
  </Section>
));

// Custom Hooks
const useTypedEffect = (elRef) => {
  useEffect(() => {
    const typed = new Typed(elRef.current, {
      ...TYPED_OPTIONS,
      strings: TYPED_STRINGS,
    });
    return () => typed.destroy();
  }, []);
};

const useWaitingState = () => {
  const [waitingMessage, setWaitingMessage] = useState('');
  const intervalRef = useRef(null);

  const startWaiting = useCallback(() => {
    let phaseIndex = 0;
    let messageIndex = 0;

    const updateMessage = () => {
      if (phaseIndex < WAITING_PHASES.length) {
        setWaitingMessage(WAITING_PHASES[phaseIndex][messageIndex]);
        messageIndex = (messageIndex + 1) % WAITING_PHASES[phaseIndex].length;
        if (messageIndex === 0) phaseIndex++;
      } else {
        stopWaiting();
      }
    };

    updateMessage();
    intervalRef.current = setInterval(updateMessage, 3000);
  }, []);

  const stopWaiting = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setWaitingMessage('');
  }, []);

  return {
    waitingMessage,
    startWaiting,
    stopWaiting,
    intervalRef
  };
};

const useInputState = (onSendMessage, isWaiting) => {
  const [state, setState] = useState({
    input: '',
    showTrending: false,
    showSuggestions: false,
    filteredSuggestions: [],
    isInitialFocus: true,
  });

  const updateState = useCallback((updates) => {
    setState(prev => ({ ...prev, ...updates }));
  }, []);

  const debouncedUpdateSuggestions = useMemo(
    () => debounce((value) => {
      if (value) {
        const suggestions = getSuggestions(value);
        updateState({
          filteredSuggestions: suggestions,
          showSuggestions: true,
          showTrending: false,
        });
      } else {
        updateState({
          filteredSuggestions: [],
          showSuggestions: false,
          showTrending: true,
        });
      }
    }, 200),
    [updateState]
  );

  const handleSubmit = useCallback(async (e) => {
    e?.preventDefault();
    if (state.input.trim() && !isWaiting) {
      await onSendMessage(state.input);
      updateState({
        input: '',
        showTrending: false,
        showSuggestions: false,
      });
    }
  }, [state.input, isWaiting, onSendMessage, updateState]);

  useEffect(() => {
    return () => {
      debouncedUpdateSuggestions.cancel();
    };
  }, [debouncedUpdateSuggestions]);

  return {
    state,
    updateState,
    debouncedUpdateSuggestions,
    handleSubmit,
  };
};

// Main Component
const HomeInput = ({ onSendMessage, onOpenAGTPromo }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isWaiting, setIsWaiting] = useState(false);
  const elRef = useRef(null);
  const inputRef = useRef(null);

  // Custom hooks
  useTypedEffect(elRef);
  const { waitingMessage, startWaiting, stopWaiting, intervalRef } = useWaitingState();
  const { state, updateState, debouncedUpdateSuggestions, handleSubmit } = useInputState(onSendMessage, isWaiting);

  // Random cards
  const randomPromptCards = useMemo(() => 
    [...PROMPT_CARDS].sort(() => 0.5 - Math.random()).slice(0, 2),
    []
  );

  // Handlers
  const handleCardClick = useCallback((text, comingSoon) => {
    if (!comingSoon) {
      if (text === "AGT Ups" || text === "AGT Edu") {
        onOpenAGTPromo();
      } else {
        updateState({ 
          input: text, 
          showTrending: false, 
          showSuggestions: false 
        });
      }
    }
  }, [onOpenAGTPromo, updateState]);

  const handleMessageSubmit = useCallback(async (e) => {
    e?.preventDefault();
    if (state.input.trim() && !isWaiting) {
      setIsWaiting(true);
      startWaiting();
      try {
        await handleSubmit(e);
      } finally {
        setIsWaiting(false);
        stopWaiting();
      }
    }
  }, [state.input, isWaiting, handleSubmit, startWaiting, stopWaiting]);

  const handleClickAway = useCallback(() => {
    updateState({ 
      showTrending: false, 
      showSuggestions: false 
    });
  }, [updateState]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const MemoizedTrendingSuggestions = useMemo(() => React.memo(TrendingSuggestions), []);

  return (
    <CenteredContainer>
      <ContentWrapper>
        <Typography 
          variant="h2" 
          gutterBottom 
          align="center" 
          sx={{ fontSize: isMobile ? '1.5rem' : '2.5rem' }}
        >
          <span ref={elRef} />
        </Typography>

        <ClickAwayListener onClickAway={handleClickAway}>
          <Box width="100%" position="relative">
            <StyledForm onSubmit={handleMessageSubmit}>
              <InputField
                state={state}
                isWaiting={isWaiting}
                inputRef={inputRef}
                onSubmit={handleMessageSubmit}
                onStateUpdate={updateState}
                onSuggestionsUpdate={debouncedUpdateSuggestions}
              />
            </StyledForm>

            {state.showTrending && !state.input && !isWaiting && (
              <Box position="absolute" width="100%" zIndex={1}>
                <MemoizedTrendingSuggestions onSuggestionClick={handleCardClick} />
              </Box>
            )}

            {isWaiting && (
              <WaitingMessage>
                {waitingMessage}...
              </WaitingMessage>
            )}
          </Box>
        </ClickAwayListener>

        {!isWaiting && (
          <SectionsContainer>
            <SectionComponent
              title="Explore"
              cards={AGT_CARDS}
              onCardClick={handleCardClick}
              isMobile={isMobile}
            />
            <SectionComponent
              title="Ask"
              cards={randomPromptCards}
              onCardClick={handleCardClick}
              isMobile={isMobile}
            />
          </SectionsContainer>
        )}
      </ContentWrapper>
    </CenteredContainer>
  );
};

// PropTypes
HomeInput.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
  onOpenAGTPromo: PropTypes.func.isRequired,
};

InputField.propTypes = {
  state: PropTypes.shape({
    input: PropTypes.string.isRequired,
    showTrending: PropTypes.bool.isRequired,
    showSuggestions: PropTypes.bool.isRequired,
    filteredSuggestions: PropTypes.array.isRequired,
    isInitialFocus: PropTypes.bool.isRequired,
  }).isRequired,
  isWaiting: PropTypes.bool.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onStateUpdate: PropTypes.func.isRequired,
  onSuggestionsUpdate: PropTypes.func.isRequired,
};

PromptCard.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
  comingSoon: PropTypes.bool,
};

CardRow.propTypes = {
  cardData: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.elementType.isRequired,
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      comingSoon: PropTypes.bool,
    })
  ).isRequired,
  onCardClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

SectionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  cards: PropTypes.array.isRequired,
  onCardClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default React.memo(HomeInput);