import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress, Typography, Box } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Provider } from 'react-redux';
import { store } from './components/services/BackgroundSummaryService';
import { getUserBackgroundPreference, setUserBackgroundPreference } from './components/services/AGTService';
import { useMediaQuery } from '@mui/material';

import LimitedDashboard from './components/dashboard/LimitedDashboard';
import FullDashboard from './components/dashboard/FullDashboard';
import UserProfile from './components/auth/UserProfile';
import HomeInput from './components/home/HomeInput';

const getTheme = (mode) => createTheme({
  palette: {
    mode: mode === 'system' 
      ? (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light') 
      : (mode === 'light' ? 'light' : 'dark'),
    primary: {
      main: '#007AFF',
    },
    background: {
      default: mode === 'light' ? '#FFFFFF' : '#171717',
      paper: mode === 'light' ? '#F5F5F5' : '#222222',
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
  },
});

function AppContent() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backgroundType, setBackgroundType] = useState('system');
  const [activeSection, setActiveSection] = useState('home');
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  useEffect(() => {
    let timeout;

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      try {
        setUser(currentUser);
        if (currentUser) {
          const preference = await getUserBackgroundPreference(currentUser.uid);
          setBackgroundType(preference === null ? 'system' : preference);
        } else {
          setBackgroundType('system');
        }
      } catch (error) {
        console.error('Error during authentication or preference loading:', error);
        setError('An error occurred while setting up the application. Please try again.');
      } finally {
        setLoading(false);
        clearTimeout(timeout);
      }
    });

    // Set a timeout for the authentication check
    timeout = setTimeout(() => {
      setLoading(false);
      setError('Authentication timed out. Please check your connection and try again.');
    }, 10000); // 10 seconds timeout

    return () => {
      unsubscribe();
      clearTimeout(timeout);
    };
  }, [auth]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    if (location.pathname === '/auth/success' && token) {
      localStorage.setItem('token', token);
      navigate('/');
    }
  }, [location, navigate]);

  const handleLogin = useCallback((user) => {
    setUser(user);
    setActiveSection('home');
    navigate('/');
  }, [navigate]);

  const handleLogout = useCallback(() => {
    auth.signOut().then(() => {
      setUser(null);
      setBackgroundType('system');
      setActiveSection('home');
      localStorage.removeItem('token');
      navigate('/');
    }).catch((error) => {
      console.error('Error al cerrar sesión:', error);
    });
  }, [auth, navigate]);

  const handleBackgroundChange = useCallback(async (newBackgroundType) => {
    if (user) {
      try {
        await setUserBackgroundPreference(user.uid, newBackgroundType);
        setBackgroundType(newBackgroundType);
      } catch (error) {
        console.error('Error setting user background preference:', error);
      }
    } else {
      setBackgroundType(newBackgroundType);
    }
  }, [user]);

  const handleNavigate = useCallback((section) => {
    setActiveSection(section);
    navigate(`/${section}`);
  }, [navigate]);

  const handleSendMessage = useCallback((message) => {
    setActiveSection('chat');
    navigate('/chat');
    // Additional logic to send the message to the backend would go here
  }, [navigate]);

  const theme = useMemo(() => {
    const mode = backgroundType === 'system' 
      ? (prefersDarkMode ? 'dark' : 'light')
      : (backgroundType === 'light' ? 'light' : 'dark');
    return getTheme(mode);
  }, [backgroundType, prefersDarkMode]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          backgroundColor: '#171717', // Color de fondo oscuro
        }}
      >
        <CircularProgress size={80} thickness={4} sx={{ color: '#FFFFFF' }} /> {/* Cambio de color a blanco para contraste */}
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          padding: 2,
          textAlign: 'center',
          backgroundColor: '#171717', // Color de fondo oscuro
        }}
      >
        <Typography color="error" variant="h6" sx={{ color: '#FFFFFF' }}>{error}</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        transition: 'background-color 0.3s ease',
      }}>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Routes>
            <Route path="/" element={
              user ? (
                <FullDashboard 
                  user={user}
                  onLogout={handleLogout}
                  backgroundType={backgroundType}
                  onBackgroundChange={handleBackgroundChange}
                  activeSection={activeSection}
                  onNavigate={handleNavigate}
                  onSendMessage={handleSendMessage}
                />
              ) : (
                <LimitedDashboard 
                  onLogin={handleLogin}
                  backgroundType={backgroundType}
                  onBackgroundChange={handleBackgroundChange}
                />
              )
            } />
            <Route path="/home" element={
              user ? (
                <FullDashboard 
                  user={user}
                  onLogout={handleLogout}
                  backgroundType={backgroundType}
                  onBackgroundChange={handleBackgroundChange}
                  activeSection="home"
                  onNavigate={handleNavigate}
                  onSendMessage={handleSendMessage}
                />
              ) : (
                <Navigate to="/" />
              )
            } />
            <Route path="/chat" element={
              user ? (
                <FullDashboard 
                  user={user}
                  onLogout={handleLogout}
                  backgroundType={backgroundType}
                  onBackgroundChange={handleBackgroundChange}
                  activeSection="chat"
                  onNavigate={handleNavigate}
                  onSendMessage={handleSendMessage}
                />
              ) : (
                <Navigate to="/" />
              )
            } />
            <Route path="/profile" element={
              user ? (
                <UserProfile 
                  user={user} 
                  onLogout={handleLogout} 
                  backgroundType={backgroundType}
                  onBackgroundChange={handleBackgroundChange}
                />
              ) : (
                <Navigate to="/" />
              )
            } />
            <Route path="/auth/success" element={<Navigate to="/" />} />
          </Routes>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <CssBaseline />
        <AppContent />
      </Router>
    </Provider>
  );
}

export default App;