import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Container, 
  CircularProgress, 
  Avatar, 
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { getAuth, signOut } from 'firebase/auth';
import { Email, AccessTime, Security } from '@mui/icons-material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
}));

const UserProfile = ({ onLogout, backgroundType, onBackgroundChange }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const auth = getAuth();
  const theme = useTheme();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const idTokenResult = await currentUser.getIdTokenResult();
          setUser({
            name: currentUser.displayName || 'Usuario',
            email: currentUser.email,
            provider: idTokenResult.signInProvider,
            photoURL: currentUser.photoURL,
            creationTime: currentUser.metadata.creationTime,
          });
        } else {
          setError('No hay sesión activa');
        }
      } catch (error) {
        console.error('Error al obtener la información del usuario:', error);
        setError('Error al obtener la información del usuario');
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [auth]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      onLogout();
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      setError('Error al cerrar sesión');
    }
  };

  const handleThemeToggle = () => {
    onBackgroundChange(backgroundType === 'light' ? 'dark' : 'light');
  };

  if (loading) {
    return (
      <Container component="main" maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container component="main" maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  if (!user) {
    return (
      <Container component="main" maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography>No se encontró información del usuario</Typography>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <StyledPaper elevation={3}>
        <Avatar
          src={user.photoURL}
          alt={user.name}
          sx={{ 
            width: 100, 
            height: 100, 
            mb: 2,
            border: `2px solid ${theme.palette.primary.main}`
          }}
        />
        <Typography component="h1" variant="h5" gutterBottom>
          {user.name}
        </Typography>
        <Divider sx={{ width: '100%', my: 2 }} />
        <List sx={{ width: '100%' }}>
          <ListItem>
            <ListItemIcon>
              <Email color="primary" />
            </ListItemIcon>
            <ListItemText primary="Email" secondary={user.email} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Security color="primary" />
            </ListItemIcon>
            <ListItemText primary="Método de autenticación" secondary={user.provider} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AccessTime color="primary" />
            </ListItemIcon>
            <ListItemText primary="Fecha de registro" secondary={new Date(user.creationTime).toLocaleDateString()} />
          </ListItem>
        </List>
        <Box sx={{ mt: 3, width: '100%' }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogout}
            sx={{ mb: 2 }}
          >
            Cerrar Sesión
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={handleThemeToggle}
          >
            Cambiar a tema {backgroundType === 'light' ? 'oscuro' : 'claro'}
          </Button>
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default UserProfile;