import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

const phrases = [
  [
    "Analyzing input data",
    "Processing information",
    "Gathering relevant parameters"
  ],
  [
    "Identifying key variables",
    "Establishing causal relationships",
    "Mapping data structures"
  ],
  [
    "Formulating hypotheses",
    "Predicting outcomes",
    "Simulating scenarios"
  ],
  [
    "Checking logical consistency",
    "Evaluating hypotheses",
    "Validating models"
  ],
  [
    "Integrating new information",
    "Finalizing conclusions",
    "Preparing comprehensive response"
  ]
];

const glowAnimation = keyframes`
  0% { opacity: 0; filter: blur(10px); }
  50% { opacity: 1; filter: blur(0px); }
  100% { opacity: 0; filter: blur(10px); }
`;

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const CircleIcon = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(2),
}));

const AnimatedText = styled(Typography)(({ theme }) => ({
  animation: `${glowAnimation} 2s infinite`,
}));

const ThinkingIcon = ({ startTime }) => {
  const [currentPhrase, setCurrentPhrase] = useState('');
  const [phaseIndex, setPhaseIndex] = useState(0);

  useEffect(() => {
    const updatePhrase = () => {
      const now = Date.now();
      const elapsedTime = now - startTime;
      const newPhaseIndex = Math.min(
        Math.floor(elapsedTime / 1000),
        phrases.length - 1
      );

      if (newPhaseIndex !== phaseIndex) {
        setPhaseIndex(newPhaseIndex);
      }

      const currentPhrases = phrases[newPhaseIndex];
      const randomIndex = Math.floor(Math.random() * currentPhrases.length);
      setCurrentPhrase(currentPhrases[randomIndex]);
    };

    updatePhrase();
    const interval = setInterval(updatePhrase, 2000);

    return () => clearInterval(interval);
  }, [startTime, phaseIndex]);

  return (
    <IconContainer>
      <CircleIcon>
        <img src="/images/Icon/AGT.svg" alt="AGT Icon" width="24" height="24" />
      </CircleIcon>
      <AnimatedText variant="body1">{currentPhrase}</AnimatedText>
    </IconContainer>
  );
};

export default ThinkingIcon;