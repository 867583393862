import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const AGTUps = () => {
  return (
    <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        AGT Ups
      </Typography>
      <StyledPaper elevation={3}>
        <Typography variant="h6" gutterBottom>
          Welcome to AGT Ups!
        </Typography>
        <Typography paragraph>
          AGT Ups is a powerful tool designed to enhance your financial strategies and boost your economic performance.
        </Typography>
        <Typography paragraph>
          Stay tuned for exciting features and insights that will help you make informed financial decisions.
        </Typography>
      </StyledPaper>
    </Box>
  );
};

export default AGTUps;