import React, { useMemo } from 'react';
import { format, isToday, isYesterday, isThisWeek, isThisMonth, isThisYear, parseISO, compareDesc } from 'date-fns';
import { List, ListItem, ListSubheader, Typography, Box, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled, useTheme } from '@mui/material/styles';
import { getThemeStyles } from '../themes/themeConfig';

const getChatCategory = (date) => {
  if (!date) return 'No Date';
  try {
    const parsedDate = parseISO(date);
    if (isToday(parsedDate)) return 'Today';
    if (isYesterday(parsedDate)) return 'Yesterday';
    if (isThisWeek(parsedDate)) return 'This Week';
    if (isThisMonth(parsedDate)) return 'This Month';
    if (isThisYear(parsedDate)) return format(parsedDate, 'MMMM');
    return format(parsedDate, 'yyyy');
  } catch (error) {
    console.error('Error parsing date:', date, error);
    return 'Invalid Date';
  }
};

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  bgcolor: 'transparent',
  ...getThemeStyles('AllChatsText', theme.palette.mode),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...getThemeStyles('AllChatsText', theme.palette.mode),
  flexGrow: 1,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  ...getThemeStyles('AllChatsText', theme.palette.mode),
}));

const CategorizedChatList = ({ chats, currentChatId, onSelectChat, onMenuOpen }) => {
  const theme = useTheme();
  
  const categorizedChats = useMemo(() => {
    // Ordenar chats por updatedAt o createdAt, el más reciente primero
    const sortedChats = chats.sort((a, b) => {
      const dateA = new Date(a.updatedAt || a.createdAt);
      const dateB = new Date(b.updatedAt || b.createdAt);
      return compareDesc(dateA, dateB);
    });

    const categories = {};
    sortedChats.forEach(chat => {
      const category = getChatCategory(chat.updatedAt || chat.createdAt);
      if (!categories[category]) categories[category] = [];
      categories[category].push(chat);
    });
    return categories;
  }, [chats]);

  // Orden predefinido de categorías
  const categoryOrder = ['Today', 'Yesterday', 'This Week', 'This Month', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  return (
    <List>
      {Object.entries(categorizedChats)
        .sort(([a], [b]) => {
          const indexA = categoryOrder.indexOf(a);
          const indexB = categoryOrder.indexOf(b);
          if (indexA === -1 && indexB === -1) return a.localeCompare(b);
          if (indexA === -1) return 1;
          if (indexB === -1) return -1;
          return indexA - indexB;
        })
        .map(([category, categoryChats]) => (
          <Box key={category}>
            <StyledListSubheader>
              {category}
            </StyledListSubheader>
            {categoryChats.map((chat) => (
              <ListItem 
                button
                key={chat.id} 
                onClick={() => onSelectChat(chat.id)}
                selected={currentChatId === chat.id}
                sx={{
                  pl: 3,
                  pr: 1,
                  backgroundColor: currentChatId === chat.id 
                    ? theme.palette.action.selected 
                    : 'inherit',
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.action.selected,
                  },
                }}
              >
                <StyledTypography noWrap>
                  {chat.title || "New chat"}
                </StyledTypography>
                <StyledIconButton 
                  size="small" 
                  onClick={(event) => onMenuOpen(event, chat.id)}
                >
                  <MoreVertIcon fontSize="small" />
                </StyledIconButton>
              </ListItem>
            ))}
          </Box>
        ))}
    </List>
  );
};

export default CategorizedChatList;