import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Divider, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  GoogleAuthProvider, 
  signInWithPopup, 
  OAuthProvider, 
  setPersistence, 
  browserLocalPersistence,
  onAuthStateChanged,
  onIdTokenChanged
} from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { isAuthorized } from './AuthorizedUsers';

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCl_QY3Y0RYRNuMc541mwhBlMW2VbLiqhQ",
  authDomain: "nest-cd957.firebaseapp.com",
  projectId: "nest-cd957",
  storageBucket: "nest-cd957.appspot.com",
  messagingSenderId: "703548963271",
  appId: "1:703548963271:web:236d5d9dd29a0d8e9141e2",
  measurementId: "G-S7XKCXJFLT"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

// Configurar persistencia de sesión
setPersistence(auth, browserLocalPersistence);

const AuthContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
  width: '100%',
  maxWidth: '400px',
  margin: '0 auto',
}));

const RoundedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '100px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
  },
  marginBottom: theme.spacing(2),
  width: '100%',
}));

const AuthButton = styled(Button)(({ theme }) => ({
  borderRadius: '100px',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  color: theme.palette.text.primary,
  height: '48px',
  width: '100%',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
  },
}));

const SocialButton = styled(Button)(({ theme }) => ({
  borderRadius: '100px',
  marginTop: theme.spacing(1),
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  color: theme.palette.text.primary,
  height: '48px',
  width: '100%',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
  },
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
  width: '100%',
  textAlign: 'center',
}));

const Auth = ({ onLogin, mode, onToggleMode }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const theme = useTheme();

  useEffect(() => {
    const unsubscribeAuthState = onAuthStateChanged(auth, (user) => {
      if (user) {
        handleSuccessfulLogin(user);
      }
    });

    const unsubscribeIdToken = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        sessionStorage.setItem('authToken', token);
      } else {
        sessionStorage.removeItem('authToken');
      }
    });

    return () => {
      unsubscribeAuthState();
      unsubscribeIdToken();
    };
  }, []);

  const handleSuccessfulLogin = async (user) => {
    try {
      if (user && typeof user.getIdToken === 'function') {
        const token = await user.getIdToken();
        sessionStorage.setItem('authToken', token);
        
        const isUserAuthorized = isAuthorized(user.email);
        onLogin(user, isUserAuthorized);
      } else {
        throw new Error('Usuario no válido o método getIdToken no disponible');
      }
    } catch (error) {
      console.error('Error al obtener el token');
      setMessage('Error al iniciar sesión. Por favor, intenta de nuevo.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      let result;
      if (mode === 'login') {
        result = await signInWithEmailAndPassword(auth, email, password);
        setMessage('Inicio de sesión exitoso');
      } else {
        result = await createUserWithEmailAndPassword(auth, email, password);
        setMessage('Usuario registrado con éxito');
      }
      await handleSuccessfulLogin(result.user);
    } catch (error) {
      console.error('Error de autenticación');
      setMessage(getErrorMessage(error.code));
    }
  };

  const handleSocialLogin = async (provider) => {
    try {
      let authProvider;
      switch (provider) {
        case 'google':
          authProvider = new GoogleAuthProvider();
          authProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
          authProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');
          authProvider.setCustomParameters({
            prompt: 'select_account'
          });
          break;
        case 'microsoft':
          authProvider = new OAuthProvider('microsoft.com');
          authProvider.setCustomParameters({
            prompt: 'select_account'
          });
          break;
        case 'apple':
          authProvider = new OAuthProvider('apple.com');
          break;
        default:
          throw new Error('Proveedor no soportado');
      }
      const result = await signInWithPopup(auth, authProvider);
      
      if (window.opener && !window.opener.closed) {
        window.close();
      }

      await handleSuccessfulLogin(result.user);
      setMessage(`Inicio de sesión con ${provider} exitoso`);
    } catch (error) {
      console.error('Error de inicio de sesión social');
      setMessage(getErrorMessage(error.code));
    }
  };

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/user-not-found':
        return 'No se encontró ninguna cuenta con este correo electrónico.';
      case 'auth/wrong-password':
        return 'Contraseña incorrecta.';
      case 'auth/email-already-in-use':
        return 'Ya existe una cuenta con este correo electrónico.';
      case 'auth/weak-password':
        return 'La contraseña es demasiado débil.';
      case 'auth/invalid-email':
        return 'El formato del correo electrónico no es válido.';
      case 'auth/account-exists-with-different-credential':
        return 'Ya existe una cuenta con este correo electrónico usando un método de inicio de sesión diferente.';
      case 'auth/popup-closed-by-user':
        return 'La ventana de inicio de sesión fue cerrada antes de completar la operación.';
      default:
        return 'Ocurrió un error durante la autenticación. Por favor, intenta de nuevo.';
    }
  };

  return (
    <AuthContainer>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2, color: theme.palette.text.primary }}>
        {mode === 'login' ? 'Welcome back' : 'Create an account'}
      </Typography>
      
      <SocialButton
        startIcon={<GoogleIcon />}
        onClick={() => handleSocialLogin('google')}>
        Continue with Google
      </SocialButton>
      <SocialButton
        startIcon={<MicrosoftIcon />}
        onClick={() => handleSocialLogin('microsoft')}
      >
        Continue with Microsoft
      </SocialButton>
      <SocialButton
        startIcon={<AppleIcon />}
        onClick={() => handleSocialLogin('apple')}
      >
        Continue with Apple
      </SocialButton>

      <Divider sx={{ my: 2, width: '100%', bgcolor: theme.palette.divider }}>
        <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
          Or
        </Typography>
      </Divider>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%' }}>
        <RoundedTextField
          variant="outlined"
          required
          fullWidth
          id="email"
          label="Email address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <RoundedTextField
          variant="outlined"
          required
          fullWidth
          id="password"
          label="Password"
          name="password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <AuthButton type="submit" fullWidth variant="contained">
          {mode === 'login' ? 'Continue' : 'Register'}
        </AuthButton>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
            {mode === 'login' ? "Don't have an account?" : 'Already have an account?'}{' '}
            <Typography
              variant="body2"
              component="span"
              onClick={onToggleMode}
              sx={{ color: theme.palette.text.secondary, cursor: 'pointer' }}
            >
              {mode === 'login' ? 'Sign Up' : 'Log In'}
            </Typography>
          </Typography>
        </Box>
      </Box>

      {message && (
        <ErrorMessage>
          {message}
        </ErrorMessage>
      )}
    </AuthContainer>
  );
};

export default Auth;