import React, { useState, useCallback, memo } from 'react';
import { TextareaAutosize, IconButton, Box, Typography, Avatar } from '@mui/material';
import { ArrowUp, X } from 'lucide-react';
import { styled, useTheme } from '@mui/material/styles';
import { getThemeStyles } from '../themes/themeConfig'; // Asegúrate de que la ruta sea correcta

const InputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'transparent',
  borderRadius: '12px',
  padding: '4px',
  ...getThemeStyles('InputWrapper', theme.palette.mode),
}));

const InputRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  ...getThemeStyles('InputRow', theme.palette.mode),
}));

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: 'transparent',
  border: 'none',
  resize: 'none',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  padding: '8px',
  '&:focus': {
    outline: 'none',
  },
  ...getThemeStyles('StyledTextarea', theme.palette.mode),
}));

const AttachedFilesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  gap: '8px',
  marginBottom: '8px',
  padding: '4px',
  '&::-webkit-scrollbar': {
    height: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)',
    borderRadius: '3px',
  },
  ...getThemeStyles('AttachedFilesContainer', theme.palette.mode),
}));

const AttachedFileChip = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.2)',
  borderRadius: '20px',
  padding: '4px 12px 4px 4px',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  ...getThemeStyles('AttachedFileChip', theme.palette.mode),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  ...getThemeStyles('InputIconButton', theme.palette.mode),
}));

const OptimizedInput = memo(({ 
  onSendMessage, 
  isWaitingForResponse, 
  inputRef, 
  selectedFiles = [], 
  onRemoveFile, 
  renderFileAttachment 
}) => {
  const [inputMessage, setInputMessage] = useState('');
  const theme = useTheme();

  const handleInputChange = useCallback((e) => {
    setInputMessage(e.target.value);
  }, []);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter' && !event.shiftKey && !isWaitingForResponse) {
      event.preventDefault();
      if (inputMessage && inputMessage.trim().length > 0) {
        onSendMessage(inputMessage);
        setInputMessage('');
      }
    }
  }, [onSendMessage, isWaitingForResponse, inputMessage]);

  const handleSendClick = useCallback(() => {
    if (inputMessage && inputMessage.trim().length > 0 && !isWaitingForResponse) {
      onSendMessage(inputMessage);
      setInputMessage('');
    }
  }, [onSendMessage, inputMessage, isWaitingForResponse]);

  const renderThumbnail = useCallback((file) => {
    if (file.type.startsWith('image/')) {
      return (
        <Avatar
          src={URL.createObjectURL(file)}
          alt={file.name}
          sx={{ width: 28, height: 28, marginRight: '8px' }}
        />
      );
    }
    return null;
  }, []);

  return (
    <InputWrapper>
      {selectedFiles.length > 0 && (
        <AttachedFilesContainer>
          {selectedFiles.map((file, index) => (
            <AttachedFileChip key={index}>
              {renderThumbnail(file)}
              <Typography variant="body2" sx={{ margin: '0 8px', maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {file.name}
              </Typography>
              <StyledIconButton size="small" onClick={() => onRemoveFile && onRemoveFile(file)} sx={{ padding: 0 }}>
                <X size={16} />
              </StyledIconButton>
            </AttachedFileChip>
          ))}
        </AttachedFilesContainer>
      )}
      <InputRow>
        {renderFileAttachment && renderFileAttachment()}
        <StyledTextarea
          ref={inputRef}
          placeholder={isWaitingForResponse ? "Waiting for response..." : "Ask AGT"}
          value={inputMessage}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          minRows={1}
          maxRows={4}
          disabled={isWaitingForResponse}
        />
        <StyledIconButton 
          onClick={handleSendClick} 
          disabled={isWaitingForResponse || !inputMessage || inputMessage.trim().length === 0}
        >
          <ArrowUp size={20} />
        </StyledIconButton>
      </InputRow>
    </InputWrapper>
  );
});

export default OptimizedInput;