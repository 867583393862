import React, { useMemo } from 'react';
import { Box, Card as MuiCard, Typography } from '@mui/material';
import { Image, GitBranch, Lightbulb, Search } from 'lucide-react';
import { styled } from '@mui/system';
import { getThemeStyles } from '../themes/themeConfig';

const CustomCard = styled(MuiCard)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  borderRadius: '30px',
  marginBottom: '10px',
  transition: 'box-shadow 0.3s, border 0.3s',
  backgroundColor: 'transparent',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(10px)',

  '&:hover': {
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)',
    border: '1px solid rgba(255, 255, 255, 0.4)',
  },

  [theme.breakpoints.down('sm')]: {
    backdropFilter: 'none',
  },
  ...getThemeStyles('CustomCard', theme.palette.mode),
}));

const CustomCardContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '15px',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: 'white',
  gap: '10px',
  ...getThemeStyles('CustomCardContent', theme.palette.mode),
}));

const promptCards = [
  { Icon: Image, text: "Muéstrame una imagen", color: "#3B82F6" },
  { Icon: GitBranch, text: "Haz un diagrama", color: "#EF4444" },
  { Icon: Lightbulb, text: "Enseñame algo nuevo", color: "#F59E0B" },
  { Icon: Search, text: "Busca en la web", color: "#10B981" }
];

const PromptCard = React.memo(({ Icon, text, color, onClick }) => (
  <CustomCard onClick={onClick}>
    <CustomCardContent>
      <Icon size={24} color={color} />
      <Typography variant="body2">
        {text}
      </Typography>
    </CustomCardContent>
  </CustomCard>
));

const PromptCards = ({ onSelectPrompt }) => {
  const cards = useMemo(() => promptCards.map((card, index) => (
    <PromptCard
      key={index}
      Icon={card.Icon}
      text={card.text}
      color={card.color}
      onClick={() => onSelectPrompt(card.text)}
    />
  )), [onSelectPrompt]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
      {cards}
    </Box>
  );
};

export default React.memo(PromptCards);